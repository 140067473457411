import { Link } from "react-router-dom";
import { DescriptionComponent, FormComponent } from "..";
export const PreloginComponent = (props: any) => {
  const {
    description,
    noHeadingDesciptions,
    formInput,
    textInput,
    buttonInput,
    forgetPassword,
    passwordGuideLineMsgInput,
    options,
    orderedList
  } = props.loginAuthObj;

  return (
    <>
      <div className="conatiner" role="main">
        <div className="login-holder">
          <div className="row h-100">
            <div className="logincontainer d-md-flex align-items-center justify-content-between">
              <DescriptionComponent
                classname={noHeadingDesciptions.classname}
                description={noHeadingDesciptions.description}
                description2={noHeadingDesciptions?.description2}
              />
              <div className="login-form mobile-login-form">
                <DescriptionComponent
                  headingclassname={description.headingclassname}
                  level={description.level}
                  heading={description.heading}
                  classname={description.classname}
                  description={description.description}
                  description2={description.description2}
                />
                <FormComponent
                  formdata={formInput}
                  md={12}
                  inputdata={textInput}
                  btndata={buttonInput}
                  descData={passwordGuideLineMsgInput}
                  optionData={options}
                  orderedList={orderedList}
                />
                {forgetPassword?.showForgetPassword && (
                  <div className="forgotpassword pt-1 pt-md-4">
                    <Link
                      to={`/${forgetPassword.client}/forgotpassword`}
                      aria-label={forgetPassword.arialabel}
                      className={forgetPassword.showForgetPassword ? "btn-link btn-forgetpassword" : "hidden"}
                    >
                      {forgetPassword.forgotPasswordLabel && forgetPassword.forgotPasswordLabel
                        ? forgetPassword.forgotPasswordLabel.slice(0, 1).toUpperCase() +
                          forgetPassword.forgotPasswordLabel
                            .slice(1, forgetPassword.forgotPasswordLabel.length)
                            .toLowerCase()
                        : ""}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreloginComponent;
