import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import _ from "underscore";
import { RootState } from "../../reducers";
import { EventUtils, ObjectUtils } from "../../utils";
import { setPageSetting } from "../../actions";
import { useNavigate } from "react-router-dom";
import { deviceType
} from "../../constants";

export const SummaryEoi = (props: any) => {
  const navigate = useNavigate();
  const contentMainMenu = useSelector((state: RootState) => state.common.screens);
  const enrolmentSummary = props.enrolmentSummaryEoiData ? props.enrolmentSummaryEoiData : {};
  const pageTitle = enrolmentSummary?.otherTabName;
  document.title = pageTitle;
  const handleEditAction = (link: any, linkID: any) => {
    let notMulti: any = true;
    const benefitName = link.split("/")[0];
    const actionLink = `${benefitName}/${linkID}`;
    let menuItem;
    if (deviceType === "Desktop") {
      _.each(contentMainMenu, (item: any) => {
        if (item.link instanceof Array) {
          const obj = _.where(item.link, { actinLink: actionLink.toUpperCase() });
          if (obj && obj.length !== 0) {
            menuItem = { ...item };
            menuItem.link = "Benefits";
            notMulti = false;
            return;
          }
        }
      });
    } else {
      menuItem = _.find(contentMainMenu, (item) => (item.link || "").toUpperCase() === (actionLink || "").toUpperCase());
    }
    setPageSetting(menuItem ? menuItem.name : "WELCOME", false, notMulti);
    navigate(`/${menuItem ? menuItem.link : ""}`);
  };
  const contentEOI = enrolmentSummary?.contentResource?.controlResource?.PendingEOIs;
  const contentPackages = enrolmentSummary?.contentResource?.controlResource.Packages;
  const contentSubscriptions = enrolmentSummary?.contentResource?.controlResource.Subscriptions;
  const captionNoteEoi = ObjectUtils.extractLabelFromObject(contentEOI, "lblEOIPendingCaption");
  const formNoteEoi = ObjectUtils.extractLabelFromObject(contentEOI, "lblEOIFormsNote");
  const lblTerminatedCaption = ObjectUtils.extractLabelFromObject(contentSubscriptions, "lblTerminatedCaption");
  const lblCoverageWaivedCaption = ObjectUtils.extractLabelFromObject(contentSubscriptions, "lblCoverageWaivedCaption");
  const lblPremiumWaivedCaption = ObjectUtils.extractLabelFromObject(contentSubscriptions, "lblPremiumWaivedCaption");
  const lblPackageCaption = ObjectUtils.extractLabelFromObject(contentPackages, "lblPackageCaption");
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  const benefitLabel = enrolmentSummary?.eoiPendingSubHeaderTemplate ? enrolmentSummary?.eoiPendingSubHeaderTemplate.benefit : "";
  const optionLabel = enrolmentSummary?.eoiPendingSubHeaderTemplate ? enrolmentSummary?.eoiPendingSubHeaderTemplate?.option : "";
  const descLabel = enrolmentSummary?.eoiPendingSubHeaderTemplate ? enrolmentSummary.eoiPendingSubHeaderTemplate?.description : "";
  const costLabel = enrolmentSummary?.eoiPendingSubHeaderTemplate ? enrolmentSummary?.eoiPendingSubHeaderTemplate?.cost : "";

  const lblPackageName = enrolmentSummary?.packageHeader ? enrolmentSummary?.packageHeader?.package : "";
  const lblPackageDescription = enrolmentSummary?.packageHeader ? enrolmentSummary.packageHeader?.description : "";
  const lblPackageCost = enrolmentSummary?.packageHeader ? enrolmentSummary?.packageHeader?.cost : "";
  const lblPackageFlex = enrolmentSummary?.packageHeader ? enrolmentSummary?.packageHeader?.flex : "";
  const lblPackageDeductions = enrolmentSummary?.packageHeader ? enrolmentSummary?.packageHeader?.deduction : "";
  const lblPremiumWaivedName = enrolmentSummary?.premiumWaivedSubscriptionHeader ? enrolmentSummary?.premiumWaivedSubscriptionHeader?.benefit : "";
  const lblPremiumWaivedOption = enrolmentSummary?.premiumWaivedSubscriptionHeader ? enrolmentSummary?.premiumWaivedSubscriptionHeader?.option : "";
  const lblPremiumWaivedDescription = enrolmentSummary.premiumWaivedSubscriptionHeader ? enrolmentSummary.premiumWaivedSubscriptionHeader?.description : "";
  const lblPremiumWaived = enrolmentSummary?.premiumWaivedSubscriptionHeader ? enrolmentSummary?.premiumWaivedSubscriptionHeader?.premiumWaived : "";
  const lblCoverageWaivedBenefit = enrolmentSummary.coverageWaivedSubscriptionsHeader ? enrolmentSummary.coverageWaivedSubscriptionsHeader?.benefit : "";
  const lblCoveregaWaived = enrolmentSummary?.coverageWaivedSubscriptionsHeader ? enrolmentSummary?.coverageWaivedSubscriptionsHeader?.option : "";
  const lblTerminatedBenefit = enrolmentSummary?.terminatedSubscriptionsHeader ? enrolmentSummary?.terminatedSubscriptionsHeader?.benefit : "";
  const lblTerminatedOption = enrolmentSummary?.terminatedSubscriptionsHeader ? enrolmentSummary?.terminatedSubscriptionsHeader?.option : "";
  const pendingEOI: any = [];
  _.each(enrolmentSummary?.benefits?.pendingEOISInfo, (benefit) => {
    pendingEOI.push(
      <div key={`div-${benefit?.benefit}-ID`} className="pendingeoi singleitem pt-3">
        <Row className="">
          <Col className="">
            <span className="visuallyhidden">{benefitLabel}</span>
            {benefit?.benefit}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{optionLabel}</span>
            {benefit?.option}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{descLabel}</span>
            {benefit?.description}
          </Col>
          <Col className="">
            <span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: costLabel }} />
            {benefit.costs}
          </Col>
        </Row>
      </div>
    );
  });

  const packageList: any = [];
  _.each(enrolmentSummary?.benefits?.packagesListInfo, (benefit) => {
    packageList.push(
      <div key={`div-${benefit?.packageName}-ID`} className="singleitem pt-3">
        <Row className="">
          <Col className="">
            <span className="visuallyhidden">{lblPackageName}</span>
            {benefit?.packageName}
            {benefit?.editButton != null && (
              <span
              className="editicon icon-action"
              role="button"
              tabIndex={0}
              onClick={() => {
                benefit?.editButton?.enabled && handleEditAction(benefit?.editButton?.link, benefit?.editButton?.linkID);
              }}
              onKeyDown={(e) => EventUtils.keyPressed(e) && benefit?.editButton?.enabled && handleEditAction(benefit?.editButton?.link, benefit?.editButton?.linkID)}
              aria-label={editIcon?.textToRead + "-" + benefit?.benefit + " " + benefit?.option + " " + benefit?.description}
              aria-disabled={!benefit?.editButton?.enabled}
              >
                <FontAwesomeIcon
                  // style={ !benefit.editButton.enabled && {opacity: 0.5, pointerEvents: "none"}}
                  icon={faPencilAlt}
                />
              </span>
            )}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblPackageDescription}</span>
            {benefit?.description}
          </Col>
          <Col className="">
            <span className="visuallyhidden" dangerouslySetInnerHTML={{ __html: lblPackageCost }} />
            {benefit?.cost}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblPackageFlex}</span>
            {benefit?.flex}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblPackageDeductions}</span>
            {benefit?.deductions}
          </Col>
        </Row>
      </div>
    );
  });

  const premiumWaived: any = [];
  _.each(enrolmentSummary?.benefits?.premiumWaivedSubscriptionList, (benefit) => {
    premiumWaived.push(
      <div key={`div-${benefit?.benefit}-ID`} className="singleitem pt-3">
        <Row className="">
          <Col className="">
            <span className="visuallyhidden">{lblPremiumWaivedName}</span>
            {benefit?.benefit}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblPremiumWaivedOption}</span>
            {benefit?.option}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblPremiumWaivedDescription}</span>
            {benefit?.description}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblPremiumWaived}</span>
            {benefit?.premiumWaived}
          </Col>
        </Row>
      </div>
    );
  });

  const coverageWaived: any = [];
  _.each(enrolmentSummary?.benefits?.coverageWaivedSubscriptionList, (benefit) => {
    coverageWaived.push(
      <div key={`div-${benefit?.benefit}-ID`} className="singleitem pt-3">
        <Row className="">
          <Col className="">
            <span className="visuallyhidden">{lblCoverageWaivedBenefit}</span>
            {benefit?.benefit}
            {benefit?.editButton != null && (
              <span
                className="editicon icon-action"
                role="button"
                tabIndex={0}
                aria-disabled={!benefit?.editButton?.enabled}
                onClick={() => {
                  benefit?.editButton?.enabled && handleEditAction(benefit?.editButton?.link, benefit?.editButton?.linkID);
                }}
                onKeyDown={(e) => EventUtils.keyPressed(e) && benefit?.editButton?.enabled && handleEditAction(benefit?.editButton?.link, benefit?.editButton?.linkID)}
              >
                <FontAwesomeIcon
                  // style={ !benefit.editButton.enabled && {opacity: 0.5, pointerEvents: "none"}}
                  icon={faPencilAlt}
                />
              </span>
            )}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblCoveregaWaived}</span>
            {benefit?.coverageWaived}
          </Col>
        </Row>
      </div>
    );
  });

  const terminatedList: any = [];
  _.each(enrolmentSummary?.benefits?.terminatedSubscriptionsList, (benefit) => {
    terminatedList.push(
      <div key={`div-${benefit?.benefit}-ID`} className="singleitem pt-3">
        <Row className="">
          <Col className="">
            <span className="visuallyhidden">{lblTerminatedBenefit}</span>
            {benefit?.benefit}
          </Col>
          <Col className="">
            <span className="visuallyhidden">{lblTerminatedOption}</span>
            {benefit?.option}
          </Col>
        </Row>
      </div>
    );
  });

  return (
    <div className="enrollmentsummary tabtwo clearfix">
      {pendingEOI?.length > 0 && (
        <>
          <h1 className="caption">{captionNoteEoi?.display}</h1>
          <Card>
            <Card.Header className="p-md-3">
              <Row>
                <Col className="">
                  <h2>{benefitLabel}</h2>
                </Col>
                <Col className="">
                  <h2>{optionLabel}</h2>
                </Col>
                <Col className="">
                  <h2>{descLabel}</h2>
                </Col>
                <Col className="">
                  <h2 dangerouslySetInnerHTML={{ __html: costLabel }} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-md-3">{pendingEOI}</Card.Body>
            <Card.Footer className="p-md-3">
              <Row>
                <Col className="col-12">{formNoteEoi?.display}</Col>
              </Row>
            </Card.Footer>
          </Card>{" "}
        </>
      )}

      {packageList.length > 0 && (
        <>
          <h3>{lblPackageCaption.display}</h3>
          <Card>
            <Card.Header>
              <Row>
                <Col className="">
                  <h2>{lblPackageName}</h2>
                </Col>
                <Col className="">
                  <h2>{lblPackageDescription}</h2>
                </Col>
                <Col className="">
                  <h2 dangerouslySetInnerHTML={{ __html: lblPackageCost }} />
                </Col>
                <Col className="">
                  <h2>{lblPackageFlex}</h2>
                </Col>
                <Col className="">
                  <h2>{lblPackageDeductions}</h2>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="">{packageList}</Card.Body>
          </Card>{" "}
        </>
      )}

      {premiumWaived?.length > 0 && (
        <>
          <h3>{lblPremiumWaivedCaption?.display}</h3>
          <Card>
            <Card.Header>
              <Row>
                <Col className="">
                  <h2>{lblPremiumWaivedName}</h2>
                </Col>
                <Col className="">
                  <h2>{lblPremiumWaivedOption}</h2>
                </Col>
                <Col className="">
                  <h2>{lblPremiumWaivedDescription}</h2>
                </Col>
                <Col className="">
                  <h2>{lblPremiumWaived}</h2>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="">{premiumWaived}</Card.Body>
          </Card>{" "}
        </>
      )}

      {coverageWaived?.length > 0 && (
        <>
          <h3>{lblCoverageWaivedCaption?.display}</h3>
          <Card>
            <Card.Header>
              <Row>
                <Col className="">
                  <h2>{lblCoverageWaivedBenefit}</h2>
                </Col>
                <Col className="">
                  <h2>{lblCoveregaWaived}</h2>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="">{coverageWaived}</Card.Body>
          </Card>{" "}
        </>
      )}

      {terminatedList.length > 0 && (
        <>
          <h3>{lblTerminatedCaption.display}</h3>
          <Card>
            <Card.Header>
              <Row className="">
                <Col className="">
                  <h2>{lblTerminatedBenefit}</h2>
                </Col>
                <Col className="">
                  <h2>{lblTerminatedOption}</h2>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="">{terminatedList}</Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};

export default SummaryEoi;
