import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import _ from "underscore";
import { ObjectUtils } from "../../utils";
import { ButtonComponent } from "../Button";
import { navigatesTo } from "../../actions";
import { useNavigate } from "react-router-dom";
export const SummaryFlex = (props: any) => {
  const flexSummary = props?.flexSummaryData ? props?.flexSummaryData?.displayFlex : {};
  const flexHeader = flexSummary?.flexHeader ? flexSummary?.flexHeader : {};
  const flexColumnCount =
    ((flexHeader?.assignedFlex && 1) || 0) + ((flexHeader?.deductions && 1) || 0) + ((flexHeader?.earnedFlex && 1) || 0) + ((flexHeader?.flexBenefit && 1) || 0) + ((flexHeader?.salesTax && 1) || 0);

  const contents = props?.flexSummaryData?.contentResource ? props?.flexSummaryData?.contentResource?.contents : {};
  const lblBtnEdit = ObjectUtils.extractLabelFromObject(contents, "btnChange");
  const pageTitle = flexSummary?.flexTitle;
  document.title = pageTitle;
  const flexDescription = flexSummary?.flexDescription;
  const flexDescriptionProrating = flexSummary?.flexDescriptionProrating;
  const flexDescriptionNoRemaining = flexSummary?.flexDescriptionNoRemaining;
  const excessFlex = flexSummary?.excessFlex;
  const proratedFlex = flexSummary?.proratedFlex;
  const flexAdjustmentText = flexSummary?.flexAdjustmentText;
  const wellness = flexSummary?.wellness;
  const proratedNote = flexSummary?.proratedNote;
  const disclaimerText = flexSummary?.disclaimerText;
  const endOfYearNote = flexSummary?.endOfYearNote;
  const endOfYearNoteList = flexSummary?.endOfYearNoteList;
  const endOfYearNoteEnd = flexSummary?.endOfYearNoteEnd;
  const isEditButtonVisible = props?.flexSummaryData?.isbtnEditFlexVisible;
  const actionLink = props?.flexSummaryData?.actionlink;
  const benefitTitle = flexHeader?.flexBenefit;
  const assignedFlexTitle = flexHeader?.assignedFlex;
  const deductionsTitle = flexHeader?.deductions;
  const salesTaxTitle = flexHeader?.salesTax;
  const earnedFlexTitle = flexHeader?.earnedFlex;
  const flexFooterList = flexSummary?.flexFooter || [];
  const flexRows: any = [];
  const flexRowsList = flexSummary?.flexRows ? flexSummary?.flexRows : [];

  const flexRowTotalIndexList: any = [];
  _.each(flexFooterList, (totalItem, index) => {
    const totalIndex = _.findLastIndex(flexRowsList, { flexDisplayGroupId: totalItem.flexDisplayGroupId });
    if (totalIndex !== -1) {
      flexRowTotalIndexList.push({
        flexRowIndex: totalIndex,
        flexFooterIndex: index
      });
    }
  });
  _.each(flexRowsList, (benefit, index) => {
    flexRows.push(
      <Row className="singleitem" key={`div-flexRow-${benefit?.flexName}-${index}-ID`}>
        {benefitTitle && (
          <Col data-title="Allocations To">
            <span className="visually-hidden">{benefitTitle}: &nbsp;</span>
            {benefit?.flexName || "\u00A0"}
          </Col>
        )}
        {assignedFlexTitle && (
          <Col data-title="Amount Applied">
            <span className="visually-hidden">{assignedFlexTitle}: &nbsp;</span>
            {benefit?.flexAmount || "\u00A0"}
          </Col>
        )}
        {deductionsTitle && (
          <Col data-title="Payroll Deductions Per Pay">
            <span className="visually-hidden">{deductionsTitle}: &nbsp;</span>
            {benefit?.deductionsAmount || "\u00A0"}
          </Col>
        )}
        {salesTaxTitle && (
          <Col data-title="Sales Tax">
            <span className="visually-hidden">{salesTaxTitle}: &nbsp;</span>
            {benefit?.salesTaxAmount || "\u00A0"}
          </Col>
        )}
        {earnedFlexTitle && (
          <Col data-title="Earned Flex">
            <span className="visually-hidden">{earnedFlexTitle}: &nbsp;</span>
            {benefit?.earnedFlexAmount || "\u00A0"}
          </Col>
        )}
      </Row>
    );

    if (flexRowTotalIndexList.length > 0) {
      const matchedTotalIndex = _.findWhere(flexRowTotalIndexList, { flexRowIndex: index });
      if (typeof matchedTotalIndex !== "undefined") {
        const totalValue = flexFooterList[matchedTotalIndex?.flexFooterIndex];
        flexRows.push(
          <Row className="total-row" key={`div-flexRow-${benefit?.flexName}-total-${totalValue?.totalLabel}-${index}-ID`}>
            {totalValue?.totalLabel && <Col>{totalValue?.totalLabel || "\u00A0"}</Col>}
            {totalValue?.totalAmount && <Col className="total-col-sum">{totalValue?.totalAmount || "\u00A0"}</Col>}
            {deductionsTitle && <Col className="col-empty">&nbsp;</Col>}
            {salesTaxTitle && <Col className="col-empty">&nbsp;</Col>}
            {earnedFlexTitle && <Col className="col-empty">&nbsp;</Col>}
          </Row>
        );
      }
    }
  });

  const matchRows: any = [];
  const matchRowsList = flexSummary?.matchRows ? flexSummary?.matchRows : [];
  _.each(matchRowsList, (benefit) => {
    matchRows.push(
      <Row className="singleitem" key={`div-matchRow-${benefit?.flexName || "\u00A0"}-ID`}>
        <Col data-title="Allocations To">
          <span className="visually-hidden">{benefitTitle}: &nbsp;</span>
          {benefit?.flexName || "\u00A0"}
        </Col>
        <Col data-title="Amount Applied">
          <span className="visually-hidden">{assignedFlexTitle}: &nbsp;</span>
          {benefit?.flexAmount || "\u00A0"}
        </Col>
        <Col data-title="Payroll Deductions Per Pay">
          <span className="visually-hidden">{deductionsTitle}: &nbsp;</span>
          {benefit.deductionsAmount || "\u00A0"}
        </Col>
        <Col data-title="Sales Tax">
          <span className="visually-hidden">{salesTaxTitle}: &nbsp;</span>
          {benefit?.salesTaxAmount || "\u00A0"}
        </Col>
        <Col data-title="Earned Flex">
          <span className="visually-hidden">{earnedFlexTitle}: &nbsp;</span>
          {benefit?.earnedFlexAmount || "\u00A0"}
        </Col>
      </Row>
    );
  });

  const vacationFlexRow = !flexSummary?.vacationFlexRow ? null : (
    <Row className="singleitem" key={`div-matchRow-${flexSummary?.vacationFlexRow?.flexBenefit}-ID`}>
      {flexSummary?.vacationFlexRow?.flexBenefit && (
        <Col data-title="Allocations To">
          <span className="visually-hidden">{benefitTitle}: &nbsp;</span>
          {flexSummary?.vacationFlexRow?.flexBenefit || "\u00A0"}
        </Col>
      )}
      {flexSummary?.vacationFlexRow?.assignedFlex && (
        <Col data-title="Amount Applied">
          <span className="visually-hidden">{assignedFlexTitle}: &nbsp;</span>
          {flexSummary?.vacationFlexRow?.assignedFlex || "\u00A0"}
        </Col>
      )}
      {flexSummary?.vacationFlexRow?.deductions && (
        <Col data-title="Payroll Deductions Per Pay">
          <span className="visually-hidden">{deductionsTitle}: &nbsp;</span>
          {flexSummary?.vacationFlexRow?.deductions || "\u00A0"}
        </Col>
      )}
      {flexSummary?.vacationFlexRow?.salesTax && (
        <Col data-title="Sales Tax">
          <span className="visually-hidden">{salesTaxTitle}: &nbsp;</span>
          {flexSummary?.vacationFlexRow?.salesTax || "\u00A0"}
        </Col>
      )}
      {flexSummary?.vacationFlexRow?.earnedFlex && (
        <Col data-title="Earned Flex">
          <span className="visually-hidden">{earnedFlexTitle}: &nbsp;</span>
          {flexSummary?.vacationFlexRow?.earnedFlex || "\u00A0"}
        </Col>
      )}
    </Row>
  );

  const navigate = useNavigate();
  const handleEditAction = (link: any) => {
    navigatesTo(navigate, link);
  };
  return (
    <div className="enrollmentsummary tabtwo clearfix">
      {pageTitle && <h1 className="caption clearfix">{pageTitle}</h1>}
      {flexDescription && (
        <div className="pb-4">
          {/* <span className="d-block" role='alert' aria-label={flexDescription} dangerouslySetInnerHTML={{__html: flexDescription}} /> */}
          <Fragment>
            <span className="d-block" dangerouslySetInnerHTML={{ __html: flexDescription }} />
          </Fragment>
        </div>
      )}
      {flexDescriptionProrating && (
        <div className="alertnote pb-4">
          <Fragment>
            {" "}
            <span className="d-block" role="alert" dangerouslySetInnerHTML={{ __html: flexDescriptionProrating }} />
          </Fragment>
        </div>
      )}
      {flexDescriptionNoRemaining && (
        <div className="alertnote pb-4">
          <Fragment>
            <span className="d-block" role="alert" dangerouslySetInnerHTML={{ __html: flexDescriptionNoRemaining }} />
          </Fragment>
        </div>
      )}
      {excessFlex && (
        <div className="alertnote pb-4">
          <Fragment>
            <span className="d-block" role="alert" dangerouslySetInnerHTML={{ __html: excessFlex }} />
          </Fragment>
        </div>
      )}
      {proratedFlex && (
        <div className="alertnote pb-4">
          <Fragment>
            <span className="d-block" role="alert" dangerouslySetInnerHTML={{ __html: proratedFlex }} />
          </Fragment>
        </div>
      )}

      <Card className={`ml-summaryflex column-${flexColumnCount}`}>
        {flexRows.length > 0 && (
          <>
            <Card.Header className="p-3">
              <Row className="singleitem">
                {benefitTitle && <Col data-title="Allocations To">{benefitTitle}</Col>}
                {assignedFlexTitle && <Col data-title="Amount Applied">{assignedFlexTitle}</Col>}
                {deductionsTitle && <Col data-title="Payroll Deductions Per Pay">{deductionsTitle}</Col>}
                {salesTaxTitle && <Col data-title="Sales Tax">{salesTaxTitle}</Col>}
                {earnedFlexTitle && <Col data-title="Earned Flex">{earnedFlexTitle}</Col>}
              </Row>
            </Card.Header>
            <Card.Body className="p-3">
              {flexRows}
              {matchRows.length > 0 && matchRows}
              {vacationFlexRow && flexSummary?.vacationFlexRow?.flexBenefit && vacationFlexRow}
            </Card.Body>
          </>
        )}
        {(flexAdjustmentText || wellness || proratedNote || disclaimerText || endOfYearNote || endOfYearNoteList || endOfYearNoteEnd || isEditButtonVisible) && (
          <Card.Footer className="p-3">
            {flexAdjustmentText && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-warning">{flexAdjustmentText}</div>
                </Col>{" "}
              </Row>
            )}
            {wellness && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-warning">{wellness}</div>
                </Col>{" "}
              </Row>
            )}
            {proratedNote && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-danger">{proratedNote}</div>
                </Col>{" "}
              </Row>
            )}
            {disclaimerText && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-warning" dangerouslySetInnerHTML={{ __html: disclaimerText }} />
                </Col>{" "}
              </Row>
            )}
            {endOfYearNote && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-warning">{endOfYearNote}</div>
                </Col>{" "}
              </Row>
            )}
            {endOfYearNoteList && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-warning">{endOfYearNoteList}</div>
                </Col>{" "}
              </Row>
            )}
            {endOfYearNoteEnd && (
              <Row>
                <Col className="col-12">
                  <div className="alert alert-warning">{endOfYearNoteEnd}</div>
                </Col>{" "}
              </Row>
            )}
            {isEditButtonVisible && (
              <Row>
                <Col className="">
                  <div className="d-flex justify-content-end">
                    <ButtonComponent
                      btndata={[
                        {
                          variant: "outline-secondary btn-change",
                          display: lblBtnEdit.display,
                          onSubmit: () => handleEditAction(actionLink)
                        }
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Card.Footer>
        )}
      </Card>
    </div>
  );
};
export default SummaryFlex;
