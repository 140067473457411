import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Button } from "react-bootstrap";
import { DeletePersonIcon, ToolTipIcon } from "../../assets/icons";
import { EventUtils } from "../../utils";

export const ButtonComponent = (props: any) => {
  const { shouldWrapDiv, btndata, disabled, handleRowLevelEvent } = props;
  const handleClickEvent = (event: any, index: number) => {
    if (btndata?.[index]?.onSubmit) {
      event.preventDefault();
      btndata?.[index]?.onSubmit(event);
    }
    if (handleRowLevelEvent) {
      event.preventDefault();
      handleRowLevelEvent(event);
    }
  };

  const buttonUIHandler = (padlevel: string | undefined, ariaLabel: string | undefined, item: any, index: number) => {
    return (
      <Fragment>
        {!item.icon && (
          <Button
            type={item?.type || "button"}
            variant={item?.variant}
            bsPrefix={item?.classname}
            name={item?.display}
            onClick={(event) => handleClickEvent(event, index)}
            // onKeyDown={(e) => EventUtils.keyPressed(e) && handleClickEvent(e, index)}
            disabled={disabled}
            aria-label={ariaLabel}
            {...item}
          >
            {item?.display}
          </Button>
        )}

        {item?.isSpanOnly && item?.icon ? (
          <span
            role="button"
            className={item?.className}
            aria-label={ariaLabel}
            tabIndex={0}
            onClick={(event) => handleClickEvent(event, index)}
            onKeyDown={(e) => EventUtils.keyPressed(e) && handleClickEvent(e, index)}
          >
            {item?.type === "tooltip" && <ToolTipIcon />}
            {item?.type === "delete" && <DeletePersonIcon />}
            <FontAwesomeIcon icon={item?.icon} />
          </span>
        ) : (
          item?.icon && (
            <Button
              variant={item?.variant}
              aria-label={ariaLabel}
              bsPrefix={item?.iconclassname}
              onClick={(event) => handleClickEvent(event, index)}
              // onKeyDown={(e) => EventUtils.keyPressed(e) && handleClickEvent(e, index)}
            >
              {item?.rightpos ? (
                <span className={item?.displayclass}>{item?.display}</span>
              ) : (
                item?.display && (
                  <span role="button" className={item?.className}>
                    {item?.display}
                  </span>
                )
              )}
              {/* {!StringUtils.isEmpty(item?.icon, true) && ( */}
              <span
                role="button"
                className={item?.className}
                style={{ cursor: "pointer" }}
                // data-tid={item?.datatid} data-title={item?.datatitle}
              >
                {item?.type === "tooltip" && <ToolTipIcon />}
                {item?.type === "delete" && <DeletePersonIcon />}
                <FontAwesomeIcon icon={item?.icon} />
              </span>
              {/* )} */}
            </Button>
          )
        )}
        {padlevel ? <div className={padlevel}></div> : null}
      </Fragment>
    );
  };

  return btndata?.map((items: any, index: number) => {
    const { padlevel, ariaLabel, ...item } = items || {};
    return (
      items &&
      (shouldWrapDiv ? (
        <div key={`${item?.display}-${index}`} className={shouldWrapDiv}>
          {buttonUIHandler(padlevel, ariaLabel, item, index)}
        </div>
      ) : (
        <Fragment key={`${item?.display}-${index}`}>{buttonUIHandler(padlevel, ariaLabel, item, index)}</Fragment>
      ))
    );
  });
};

export default ButtonComponent;
