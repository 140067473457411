import { Card, Table } from "react-bootstrap";
import { ObjectUtils } from "../../utils";
import { useCallback, useState } from "react";
import { CardComponent } from "../Card";
import { AlertComponent } from "../Alert";
import { Link, useNavigate } from "react-router-dom";
import { Beneficiaries } from "../../containers";
import { ButtonComponent } from "../Button";
import { DescriptionComponent } from "../Form";
import { RootState } from "../../reducers";
import { BeneficiariesListModal } from "../../interfaces/formValidation";
import { setFlexBeneficiaryBenefitID, setPageSetting } from "../../actions";
import _ from "underscore";
import { useSelector } from "react-redux";
import { deviceType } from "../../constants";

export const BeneficiariesListComponent = (props: any) => {
  //operationID:1 - For Beneficiaries All
  //operationID:2 - For Beneficiaries Reallocate
  //operationID:3 - For Summary Beneficiaries
  const navigate = useNavigate();
  const { operationID, beneficiariesAll }: any = props;
  const contentMainMenu = useSelector((state: RootState) => state.common.screens || [{}]);
  const { flexBeneficiaryBenefitID } = useSelector((state: RootState) => state.beneficiary);

  const [beneficiariesListModal, setBeneficiariesListModal] = useState<BeneficiariesListModal>({
    showEdit: flexBeneficiaryBenefitID !== -1 && operationID === 2 ? true : false,
    benefitID: -1,
    expandedAccordion: flexBeneficiaryBenefitID === -1 ? flexBeneficiaryBenefitID : 0
  });
  const getTotalPer = useCallback((beneficiary: any) => {
    let newTotal: any = 0;
    beneficiary &&
      beneficiary?.map((item: any) => {
        newTotal = item.percentage === "" ? null : item.percentage + parseInt(newTotal);
      });
    return isNaN(newTotal) ? null : newTotal;
  }, []);

  const handleEditClickEvent = (benefitID: any) => {
    setBeneficiariesListModal({
      ...beneficiariesListModal,
      showEdit: true,
      benefitID: benefitID,
      expandedAccordion: -1
    });
  };

  const handleChangeClick = (link: string, linkID: string) => {
    const benefitName = link.split("/")[0];
    let actionLink = `${benefitName}/${linkID}`.toUpperCase();

    if (link.includes("Flex?")) {
      actionLink = link;
    }
    let menuItem;
    if (deviceType === "Desktop") {
      _.each(contentMainMenu, (item: any) => {
        if (item.link instanceof Array) {
          if (link.includes("Flex?") && item.link === actionLink) {
            menuItem = item;
            return;
          } else {
            const obj: any = _.where(item.link, { actinLink: actionLink });
            if (obj && obj.length !== 0) {
              menuItem = item;
              return;
            }
          }
        } else if (link.includes("Flex?") && item.link === actionLink) {
          menuItem = item;
          return;
        }
      });
      setPageSetting(menuItem ? menuItem.name : "WELCOME", true, true);
      setFlexBeneficiaryBenefitID(linkID, benefitName, () => {
        navigate("/Beneficiaries");
      });
    } else {
      menuItem = _.find(contentMainMenu, (item) => (item?.link || "").toUpperCase() === (actionLink || "").toUpperCase());
      setPageSetting(menuItem ? menuItem.name : "WELCOME", true, false, () => {
        navigate("/Beneficiaries");
      });
    }
  };

  const hideEdit = () => {
    setBeneficiariesListModal({
      ...beneficiariesListModal,
      showEdit: false,
      benefitID: -1,
      expandedAccordion: -1
    });
  };

  const beneficiaryBenefitsInfo = beneficiariesAll?.beneficiaryBenefitsInfo ? beneficiariesAll?.beneficiaryBenefitsInfo : [];
  const content = beneficiariesAll?.contentResource ? beneficiariesAll?.contentResource?.controlResource : {};
  const contents = content?.Beneficiaries ? content?.Beneficiaries : {};
  const content1 = beneficiariesAll?.contentResource ? beneficiariesAll?.contentResource?.contents : {};
  const isCancelVisible = beneficiariesAll?.result?.length > 1 ? true : false;
  const thcPercent = ObjectUtils.extractLabelFromObject(contents, "thcPercent");
  const lblEdit = ObjectUtils.extractLabelFromObject(contents, "lblChoose");
  const lblChange = ObjectUtils.extractLabelFromObject(content1, "btnChange");
  const thcName = ObjectUtils.extractLabelFromObject(contents, "thcName");
  const thcRelationship = ObjectUtils.extractLabelFromObject(contents, "thcFormRelationship");

  const thcTrustee = ObjectUtils.extractLabelFromObject(contents, "thcTrustee");
  const thcTotal = ObjectUtils.extractLabelFromObject(contents, "thcTotal");
  const renderBeneficiarieList = (title: any, editButton: any, list: any) => {
    if (list && list.length > 0) {
      const items: any = [];
      list?.map((item: any) => {
        items.push(
          <tr key={`div-${title}-${editButton?.linkID}-ID-${item?.beneficiaryName}`}>
            <td
              dangerouslySetInnerHTML={{
                __html: `<strong>${item?.beneficiaryName}</strong><br />${item?.dateOfBirth ? item?.dateOfBirth : "n/a"}`
              }}
            />
            <td dangerouslySetInnerHTML={{ __html: item?.relationShip ? item?.relationShip : "n/a" }} />
            <td dangerouslySetInnerHTML={{ __html: item?.trustee ? item?.trustee : "n/a" }} />
            {item?.removedMsg === "" || item?.removedMsg == null ? (
              <td className="text-end">{item?.percentage}%</td>
            ) : (
              <td className="text-end">
                <span className="ml-mandatory-sign">({item?.removedMsg})</span> {item?.percentage}%
              </td>
            )}
          </tr>
        );
      });

      return (
        <>
          <div className="tbl-beneficiaries">
            <DescriptionComponent headingclassname="title" level="3" heading={title} />
            <Table>
              <thead>
                <tr>
                  <th scope="col">{thcName?.display}</th>
                  <th scope="col">{thcRelationship?.display}</th>
                  <th scope="col">{thcTrustee?.display}</th>
                  <th scope="col">{thcPercent?.display}</th>
                </tr>
              </thead>
              <tbody>{items}</tbody>
              <tfoot>
                <tr>
                  <td colSpan={3} className="text-end no-right-border">
                    <strong>{thcTotal?.display}</strong>
                  </td>
                  <td>
                    <strong className="m-0">{getTotalPer((list || []).length > 0 ? list : 0)}%</strong>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </>
      );
    }

    return null;
  };

  const renderNoBeneficiariesMessage = (title: any, message: any) => {
    return (
      <>
        <Card className={`ccard onfilebeneficiaries`}>
          <Card.Header>
            <div>
              <DescriptionComponent level="2" heading={title} />
            </div>
            <div>
              <DescriptionComponent level="2" heading={thcPercent.display} />
            </div>
          </Card.Header>
          {message && (
            <Card.Footer>
              <>
                <div className="alert alert-secondary" role="alert" dangerouslySetInnerHTML={{ __html: message }} />
              </>
            </Card.Footer>
          )}
        </Card>
      </>
    );
  };

  const beneficiaryList: any = [];
  beneficiaryList?.length === 0 &&
    beneficiaryBenefitsInfo &&
    beneficiaryBenefitsInfo?.map((benefit: any, index: number) => {
      const head: any = {
        gridtitle: benefit?.benefitName,
        headerclass: "heading-white",
        showediticon: false
      };
      const onFile = renderBeneficiarieList(benefit?.lblOnFile, benefit?.editButton, benefit?.beneficiaryCurrentInfo);
      const pending = renderBeneficiarieList(benefit?.pending, benefit?.editButton, benefit?.beneficiaryPendingInfo);
      const contingentPending = renderBeneficiarieList(benefit?.lblContingentPending, benefit?.editButton, benefit?.contingentBeneficiaryPendingInfo);
      const contingentOnFile = renderBeneficiarieList(benefit?.lblContingentOnFile, benefit?.editButton, benefit?.contingentBeneficiaryCurrentInfo);
      const noBeneficiariesMessage = renderNoBeneficiariesMessage(benefit?.lblOnFile, benefit?.noBeneficiariesNote);
      const noContBeneficiariesMessage = benefit?.isContingentBeneficiaryOn ? renderNoBeneficiariesMessage(benefit?.lblContingentOnFile, benefit?.noBeneficiariesNote) : null;
      /* E-Signature Start */
      const { viewBeneficiaryDeclaration, removedBeneficiaryError } = benefit;
      /* E-Signature End */
      beneficiaryList.push(
        <section key={`div-${benefit?.benefitID}-ID-${benefit?.benefitName}-${index}`} className="mb-md-4">
          {beneficiariesListModal?.showEdit && (benefit?.benefitID === beneficiariesListModal?.benefitID || (operationID === 2 && beneficiariesListModal.expandedAccordion === index)) ? (
            <div className="clearfix">
              <Beneficiaries
                beneficiariesAll={operationID === 1 ? true : false}
                benefitID={benefit?.benefitID}
                benefitTitle={benefit?.benefitName}
                hideEdit={hideEdit}
                handleCancelEdit={hideEdit}
                isCancelVisible={operationID === 2 ? isCancelVisible : true}
              ></Beneficiaries>
            </div>
          ) : (
            <CardComponent
              key={`d-div-${benefit?.benefitID}-${index}`}
              gridObject={{
                cardClass: " pcard",
                head
              }}
            >
              {benefit?.noBeneficiariesNote ? (
                <>
                  {noBeneficiariesMessage}
                  {noContBeneficiariesMessage}
                </>
              ) : (
                <>
                  {onFile}
                  {contingentOnFile}
                  {pending}
                  {contingentPending}
                </>
              )}
              {operationID === 3 && benefit?.editButton?.enabled && lblChange?.display && (
                <ButtonComponent
                  btndata={[
                    {
                      variant: "outline-secondary btn-change",
                      display: lblChange?.display,
                      onSubmit: () => handleChangeClick(benefit?.editButton?.link, benefit?.editButton?.linkID)
                    }
                  ]}
                />
              )}
              {removedBeneficiaryError && <AlertComponent propmessageType={`danger mb-2`} propmessage={removedBeneficiaryError} />}
              <br />
              <div className={"clearfix btnbox"}>
                {lblEdit?.display && (
                  <ButtonComponent
                    btndata={[
                      {
                        variant: "outline-secondary btn-choosebeneficiary float-start",
                        disabled: benefit?.noEditableNote,
                        display: lblEdit?.display,
                        onSubmit: () => handleEditClickEvent(benefit?.benefitID)
                      }
                    ]}
                  />
                )}
                {operationID === 1 && (
                  <>
                    {/* E-Signature Start */}
                    {benefit?.isEsignable && benefit?.noEditableNote && <AlertComponent propmessageType={`info clearfix m-0 mb-3`} propmessage={benefit?.noEditableNote} />}
                    {benefit?.isEsignable && !benefit?.noEditableNote && viewBeneficiaryDeclaration?.name && (
                      <Link
                        to="#"
                        onClick={() => {
                          "handleShowHelp(viewBeneficiaryDeclaration?.btnNavigateTo);";
                        }}
                        aria-label={viewBeneficiaryDeclaration?.name}
                        className="float-right icon-action mt-3"
                        dangerouslySetInnerHTML={{ __html: viewBeneficiaryDeclaration?.name }}
                      />
                    )}
                    {/* E-Signature End */}
                  </>
                )}
              </div>
            </CardComponent>
          )}
        </section>
      );
    });
  return <>{beneficiaryList && beneficiaryList?.length > 0 && <div> {beneficiaryList}</div>}</>;
};

export default BeneficiariesListComponent;
