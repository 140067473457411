import { InputGroup, Form } from "react-bootstrap";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMultiBenefitOption } from "../../actions/benefitActions";
import { ButtonComponent } from "..";

export const UnitCoverageComponent = (props) => {
  const { unitCoverageMessage, benefit, benefitURL } = props;
  const dispatch = useDispatch();
  const [coverageAmount, setcoverageAmount] = useState<any>(-1);
  const saveChangeOption = useCallback(
    (_coverageAmount = -1) => {
      const resultcoverageAmount = _coverageAmount >= 0 ? _coverageAmount : coverageAmount;
      dispatch(setMultiBenefitOption(benefitURL, resultcoverageAmount, true));
    },
    [dispatch, benefitURL, coverageAmount]
  );

  useEffect(() => {
    if (benefit?.unitCoverage?.unitCoverageAmount !== coverageAmount) {
      setcoverageAmount(benefit?.unitCoverage?.unitCoverageAmount);
    }
  }, [benefit?.unitCoverage?.unitCoverageAmount]);

  const handleUnitCoverageUpdateValue = (event) => {
    const formattedValue = event.target.value.replace(/[^\d]/g, "").replace(/(^(0|1-9))[\d]/g, "$1");

    setcoverageAmount(formattedValue);
  };

  const handleUnitCoverageIncrement = () => {
    const unitCoverage = benefit?.unitCoverage;
    if (unitCoverage) {
      let _coverageAmount: any = parseInt(coverageAmount);
      if (_coverageAmount + unitCoverage?.coveragePerUnit <= unitCoverage?.maximumCoverage) _coverageAmount += unitCoverage?.coveragePerUnit;
      else _coverageAmount = unitCoverage?.maximumCoverage;
      setcoverageAmount(_coverageAmount);
      saveChangeOption(_coverageAmount);
    }
  };

  const handleUnitCoverageDecrement = () => {
    const unitCoverage = benefit?.unitCoverage;
    if (unitCoverage) {
      let _coverageAmount = parseInt(coverageAmount ? coverageAmount : unitCoverage?.unitCoverageAmount);
      if (_coverageAmount - unitCoverage?.coveragePerUnit >= 0) _coverageAmount -= unitCoverage?.coveragePerUnit;
      else _coverageAmount = 0;
      setcoverageAmount(_coverageAmount);
      saveChangeOption(_coverageAmount);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (benefitURL && coverageAmount) saveChangeOption();
  };

  const incrementbtndata = [
    {
      iconclassname: "icon-action btn-nocolor",
      icon: faPlus,
      ariaLabel: "increment",
      onSubmit: handleUnitCoverageIncrement
    }
  ];
  const decrementbtndata = [
    {
      iconclassname: "icon-action btn-nocolor",
      icon: faMinus,
      ariaLabel: "decrement",
      isSpanOnly: true,
      onSubmit: handleUnitCoverageDecrement
    }
  ];

  return (
    <>
      <div className={`incrementer pt-md-3 clearfix`}>
        <span className="notetext" dangerouslySetInnerHTML={{ __html: unitCoverageMessage?.display }} />
        <div className="ml-quntitybox d-flex float-right">
          <ButtonComponent btndata={decrementbtndata} />
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control aria-label={coverageAmount} name="coverageAmount" value={coverageAmount} onChange={handleUnitCoverageUpdateValue} onBlur={handleUnitCoverageUpdateValue} />
            </InputGroup>
          </Form>
          <ButtonComponent btndata={incrementbtndata} />
        </div>
      </div>
    </>
  );
};
export default UnitCoverageComponent;
