import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent, ChoiceListComponent, DescriptionComponent } from "..";
import { saveChangeDependentCoverage } from "../../actions/benefitActions";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { setPageSetting } from "../../actions";
import { useNavigate } from "react-router-dom";

export const ChangeDependentCoverageComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { benefitURL, isMultiSelectDependents, dependentsThisPlan, dependentsOtherPlan, contents, handleClose } = props;
  const [selectedValue, setSelectedValue] = useState<any>();
  const [listDependentsThisPlan, setListDependentsThisPlan] = useState<any>();
  const [listDependentsOtherPlan, setListDependentsOtherPlan] = useState<any>();
  const sideBarMenu = useSelector((state: RootState) => state.common.screens);

  useEffect(() => {
    setSelectedValue({
      dependentsThisPlan: dependentsThisPlan ? dependentsThisPlan.findIndex((item) => item.selected === true) : "-1",
      dependentsOtherPlan: dependentsOtherPlan ? dependentsOtherPlan.findIndex((item) => item.selected === true) : "-1"
    });
    setListDependentsThisPlan(dependentsThisPlan);
    setListDependentsOtherPlan(dependentsOtherPlan);
  }, []);

  const handleSelect = (index: any, event: any) => {
    const name: any = event?.target?.name;
    const list: any = name === "dependentsOtherPlan" ? [...dependentsOtherPlan] : [...dependentsThisPlan];
    if (selectedValue[name] !== "-1" && !isMultiSelectDependents) {
      const prevInd = parseInt(selectedValue[name]);
      list[prevInd].selected = !list[prevInd].selected;
    }
    list[parseInt(index)].selected = !list[parseInt(index)].selected;
    selectedValue[name] = index;
    if (name === "dependentsOtherPlan") setListDependentsOtherPlan(list);
    else setListDependentsThisPlan(list);
    setSelectedValue(selectedValue);
  };

  const handleSave = () => {
    dispatch(saveChangeDependentCoverage(listDependentsThisPlan, listDependentsOtherPlan, benefitURL ? benefitURL : ""));
    handleClose();
  };

  const handleAddDependent = () => {
    const menuItem = sideBarMenu.find((item: any) => (item.link || "").toUpperCase() === "DEPENDENT");
    setPageSetting(menuItem ? menuItem.name : "WELCOME", true, true);
    navigate(`/${menuItem ? menuItem.link : ""}`);
  };

  const checkBoxList = (listName: any, isMultiSelectDependents: boolean) => {
    const options: any = [];
    const resultList = listName === "dependentsThisPlan" ? listDependentsThisPlan : listDependentsOtherPlan;
    const fselectedValue = listName === "dependentsThisPlan" ? selectedValue?.dependentsThisPlan : selectedValue?.dependentsOtherPlan;
    resultList?.map((opt: any, i: number) => {
      const uniqID = `${listName}-${opt.text}-${opt.value}-${i}`;
      const selectedVal = isMultiSelectDependents ? opt.selected : i === parseInt(fselectedValue) ? true : false;
      //Benefit option
      const optionsInput = {
        id: uniqID,
        type: isMultiSelectDependents ? "checkbox" : "radio",
        name: listName,
        disabled: !opt?.enabled,
        ariadisabled: !opt?.enabled,
        selected: selectedVal,
        ischecked: true,
        isCallbackParam: true,
        fgclass: "mt-1",
        handlechange: (event) => handleSelect(i, event),
        optionsinput: [
          {
            id: uniqID,
            text: opt?.text,
            value: i
          }
        ]
      };
      //Benefit option End
      options.push(
        <div id="listOp" key={uniqID} className={`w-100 float-left`}>
          <ChoiceListComponent choicelistdata={optionsInput} />
        </div>
      );
    });
    return options;
  };

  const thisPlan = checkBoxList("dependentsThisPlan", isMultiSelectDependents);
  const otherPlan = checkBoxList("dependentsOtherPlan", isMultiSelectDependents);
  const lblNoDependents = ObjectUtils.extractLabelFromObject(contents, "lblNoDependents");
  const lblAddDependentsNote = ObjectUtils.extractLabelFromObject(contents, "lblAddDependentsNote");
  const lblDependentCoveredUnderAnotherPlan = ObjectUtils.extractLabelFromObject(contents, "lblDependentCoveredUnderAnotherPlan");
  const btnLblSave = ObjectUtils.extractLabelFromObject(contents, "btnSave");
  const btnLblCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const btnAddDependent = ObjectUtils.extractLabelFromObject(contents, "btnAddDependent");

  const btnaddDependent = [
    {
      variant: "outline-secondary btn-addDependent mt-1 mb-2",
      display: btnAddDependent?.display,
      onSubmit: handleAddDependent
    }
  ];

  const buttonInput = [
    {
      variant: "outline-secondary btn-sm btn-cancel",
      display: btnLblCancel?.display,
      onSubmit: handleClose
    },
    dependentsThisPlan?.length > 0 && {
      variant: "outline-secondary btn-sm btn-save",
      display: btnLblSave?.display,
      disabled: !(dependentsThisPlan?.length > 0),
      onSubmit: handleSave
    }
  ];

  return (
    <div className={`changedependentcoverage clearfix`}>
      {dependentsThisPlan?.length > 0 ? (
        <>
          <Form.Group className="clearfix mb-0" controlId={`thisPlan`}>
            {thisPlan}
          </Form.Group>
          {dependentsOtherPlan?.length > 0 && (
            <>
              <div className={`titlebox clearfix`}>
                <DescriptionComponent level={1} headingclassname="modal-title" heading={lblDependentCoveredUnderAnotherPlan.display} />
              </div>
              <Form.Group className="clearfix" controlId={`otherPlan`}>
                {otherPlan}
              </Form.Group>
            </>
          )}
        </>
      ) : (
        <div>
          <div>{lblNoDependents.display}</div>
          <ButtonComponent btndata={btnaddDependent} />
        </div>
      )}
      <div className={`message mt-3 mb-3`}>
        <div className={`m-0`} dangerouslySetInnerHTML={{ __html: lblAddDependentsNote.display }} />
      </div>
      <div className="ml-popupbuttons d-flex justify-content-between border-top">
        <ButtonComponent btndata={buttonInput} />
      </div>
    </div>
  );
};
export default ChangeDependentCoverageComponent;
