import { Card, Col, Row } from "react-bootstrap";
import Sticky from "react-stickynode";
import { EventUtils, ObjectUtils } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import _ from "underscore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { SummaryActiveSubscriptionsModal } from "../../interfaces/formValidation";
import { useNavigate } from "react-router-dom";
import { setPageSetting } from "../../actions";
import { CustomToolTipIcon } from "../../assets/icons";
import { ModalComponent } from "../Modal";
import { deviceType } from "../../constants";

export const SummaryActiveSubscriptions = (props: any) => {
  const enrolmentSummary = props?.enrolmentSummaryData ? props?.enrolmentSummaryData : {};
  const navigate = useNavigate();
  const contentMainMenu = useSelector((state: RootState) => state.common.screens);
  const pageTitle = enrolmentSummary?.tabName;
  document.title = pageTitle;
  const [sessionsummaryActiveSubscriptions, setsessionSummaryActiveSubscriptions] = useState<SummaryActiveSubscriptionsModal>({
    showNotes: false,
    description: "",
    header: "",
    tooltipId: "",
    tooltipNum: ""
  });

  const handleEditAction = (link: any, linkID: any) => {
    let notMulti: any = true;
    const benefitName = link.split("/")[0];
    const actionLink = `${benefitName}/${linkID}`;

    let menuItem;
    if (deviceType === "Desktop") {
      _.each(contentMainMenu, (item: any) => {
        if (item.link instanceof Array) {
          const obj = _.where(item.link, { actinLink: actionLink.toUpperCase() });
          if (obj && obj.length !== 0) {
            menuItem = { ...item };
            menuItem.link = "Benefits";
            notMulti = false;
            return;
          }
        }
      });
    } else {
      menuItem = _.find(contentMainMenu, (item) => (item.link || "").toUpperCase() === (actionLink || "").toUpperCase());
    }
    setPageSetting(menuItem ? menuItem.name : "WELCOME",true, notMulti);
    navigate(`/${menuItem ? menuItem.link : ""}`);
  };

  const toggleDescription = (opt: any, tooltipNum: any) => {
    const tooltipNumList = tooltipNum ? tooltipNum.split(",") : [];
    setsessionSummaryActiveSubscriptions({
      showNotes: !sessionsummaryActiveSubscriptions.showNotes,
      description: opt?.description,
      header: opt?.benefit,
      tooltipId: "showTooltip-noteNumber-" + tooltipNum,
      tooltipNum: tooltipNumList
    });
  };

  const handleClose = () => {
    setsessionSummaryActiveSubscriptions({
      ...sessionsummaryActiveSubscriptions,
      showNotes: false
    });
  };
  const costLabel = enrolmentSummary && enrolmentSummary?.activeSubscriptionsHeader ? enrolmentSummary?.activeSubscriptionsHeader?.cost : "";
  const flexLabel = enrolmentSummary && enrolmentSummary?.activeSubscriptionsHeader ? enrolmentSummary?.activeSubscriptionsHeader?.flex : "";
  const employerCostLabel = enrolmentSummary && enrolmentSummary?.activeSubscriptionsHeader ? enrolmentSummary?.activeSubscriptionsHeader?.employerCost : "";
  const creditsAppliedLabel = enrolmentSummary && enrolmentSummary?.activeSubscriptionsHeader ? enrolmentSummary?.activeSubscriptionsHeader.creditsApplied : "";
  const deductionLabel = enrolmentSummary && enrolmentSummary?.activeSubscriptionsHeader ? enrolmentSummary?.activeSubscriptionsHeader?.deduction : "";
  const activeFooter = enrolmentSummary && enrolmentSummary.activeSubscriptionsFooter ? enrolmentSummary?.activeSubscriptionsFooter : {};
  //const contentFooter = this.props.contentFooter;
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  // const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  // const toolTipIcon =ObjectUtils.extractLabelFromObject(footerContent, "ToolTipIcon");
  // const closeButtonText =ObjectUtils.extractLabelFromObject(footerContent, "btnClose");
  const activeSubscriptions: any = [];
  const list = ((enrolmentSummary || {})?.benefits || {})?.activeSubscriptions || [];
  _.each(list, (benefit) => {
    const notesNumberList = benefit?.noteNumber ? benefit?.noteNumber.split("|") : [];
    let noteNumberString = "";
    _.each(notesNumberList, (note, i) => {
      noteNumberString += note - 1 + (i < notesNumberList.length - 1 ? "," : "");
    });
    activeSubscriptions.push(
      <div key={`div-${benefit.benefit}-ID`} className="mb-md-3 enrollmentitem">
        <Card className="m-0">
          <Card.Header>
            <div className="titleandicon d-flex position-relative pr-0">
              <div className="benefit-title flex-column">
                <h2>
                  {benefit?.benefit}
                  &nbsp;{" "}
                  {noteNumberString && (
                    <span
                      role="button"
                      aria-label={benefit?.benefit}
                      tabIndex={0}
                      className="tooltip-icon icon-action"
                      onClick={() => toggleDescription(benefit, noteNumberString)}
                      onKeyDown={(e) => EventUtils.keyPressed(e) && toggleDescription(benefit, noteNumberString)}
                    >
                      <CustomToolTipIcon />
                    </span>
                  )}
                </h2>
              </div>
              <div className="benifit-option flex-column">
                <h2 dangerouslySetInnerHTML={{ __html: benefit?.description }} />
              </div>
              <time className="pr-3" aria-hidden={!benefit?.benefitEffectiveDate} aria-label={benefit?.benefitEffectiveDate} dateTime={benefit?.benefitEffectiveDate}>
                {benefit?.benefitEffectiveDate}
              </time>
              {benefit?.editButton?.enabled && (
                <span
                  className="editicon icon-action"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    benefit?.editButton?.enabled && handleEditAction(benefit?.editButton?.link, benefit?.editButton?.linkID);
                  }}
                  aria-label={editIcon?.textToRead + "-" + benefit?.benefit + " " + benefit?.option + " " + benefit?.description}
                  aria-disabled={!benefit?.editButton?.enabled}
                  onKeyDown={(e) => EventUtils.keyPressed(e) && benefit?.editButton?.enabled && handleEditAction(benefit?.editButton?.link, benefit?.editButton?.linkID)}
                >
                  <FontAwesomeIcon
                    // style={!benefit.editButton.enabled && { opacity: 0.5, pointerEvents: "none" }}
                    icon={faPencilAlt}
                  />
                </span>
              )}
            </div>
          </Card.Header>
          <Card.Body className="p-md-0">
            <Row className="justify-content-end activesubscription-rows">
              <Col className="col-12 col-sm-8">
                <Row className="valueboxes">
                  <Col>
                    <span className="visuallyhidden">{costLabel}</span>
                    {benefit.annualCost}
                  </Col>
                  {flexLabel && (
                    <Col>
                      <span className="visuallyhidden">{flexLabel}</span>
                      {benefit?.flexDollars}
                    </Col>
                  )}
                  {employerCostLabel && (
                    <Col>
                      <span className="visuallyhidden">{employerCostLabel}</span>
                      {benefit?.employerCost}
                    </Col>
                  )}
                  {creditsAppliedLabel && (
                    <Col>
                      <span className="visuallyhidden">{creditsAppliedLabel}</span>
                      {benefit?.creditsApplied}
                    </Col>
                  )}
                  <Col className="last-column">
                    <span className="visuallyhidden">{deductionLabel}</span>
                    {benefit?.payrollDeducations}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  });

  const notesList: any = [];
  _.each(enrolmentSummary?.notes, (note, i) => {
    const tooltipNum: any = sessionsummaryActiveSubscriptions?.tooltipNum;
    _.each(tooltipNum, (tipNum) => {
      parseInt(tipNum) === i && notesList.push(<p key={`notes-${i + 1}-ID`} className="m-0" dangerouslySetInnerHTML={{ __html: note?.text }} />);
    });
  });

  const modalObject = {
    showModal: sessionsummaryActiveSubscriptions?.showNotes,
    clickHandler: handleClose,
    head: {
      modalTitle: sessionsummaryActiveSubscriptions?.header ? sessionsummaryActiveSubscriptions?.header : "\u00A0"
    }
  };

  return (
    <div className="enrollmentsummary tabone clearfix">
      <Sticky enabled={true} top={63} innerZ={100}>
        <Row className="enrollmenthead justify-content-end m-0">
          {" "}
          <Col className="col-12 col-sm-8">
            <Row className="valueboxes">
              <Col>{costLabel}</Col>
              {flexLabel && <Col>{flexLabel}</Col>}
              {employerCostLabel && <Col>{employerCostLabel}</Col>}
              {creditsAppliedLabel && <Col>{creditsAppliedLabel}</Col>}
              <Col className="last-column">{deductionLabel}</Col>
            </Row>
          </Col>
        </Row>
      </Sticky>
      <div className="enrollmentlist">{activeSubscriptions}</div>
      <div className="mb-3 enrollmentitem foottotal">
        {activeFooter?.subTotal && (
          <>
            <Row className="m-0">
              <Col className="col-12 col-sm-4 title">{activeFooter?.subTotal?.subTotalLabel}</Col>
              <Col className="col-12 col-sm-8 columnone">
                <Row className="justify-content-between valueboxes">
                  <Col>
                    <span className="visuallyhidden">{costLabel}</span>
                    {activeFooter?.subTotal?.cost}
                  </Col>
                  {flexLabel && (
                    <Col>
                      <span className="visuallyhidden">
                        {activeFooter?.subTotal?.subTotalLabel}
                        {flexLabel}
                      </span>
                      {activeFooter?.subTotal?.flex}
                    </Col>
                  )}
                  {employerCostLabel && (
                    <Col>
                      <span className="visuallyhidden">
                        {activeFooter?.subTotal?.subTotalLabel}
                        {employerCostLabel}
                      </span>
                      {activeFooter?.subTotal?.employerCost}
                    </Col>
                  )}
                  {creditsAppliedLabel && (
                    <Col>
                      <span className="visuallyhidden">{creditsAppliedLabel}</span>
                      {activeFooter?.subTotal?.creditsApplied}
                    </Col>
                  )}
                  <Col className="last-column">
                    <span className="visuallyhidden">{deductionLabel}</span>
                    {activeFooter?.subTotal?.deductions}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {activeFooter?.salesTax && (
          <>
            <Row className="m-0">
              <Col className="col-12 col-sm-4 title">{activeFooter?.salesTax?.salesTaxLabel}</Col>
              <Col className="col-12 col-sm-8 columntwo">
                <Row className="justify-content-between valueboxes">
                  <Col>&nbsp;</Col>
                  <Col>&nbsp;</Col>
                  {employerCostLabel && creditsAppliedLabel && <Col>&nbsp;</Col>}
                  <Col className="last-column">
                    <span className="visuallyhidden">{deductionLabel}</span>
                    {activeFooter?.salesTax?.salesTaxDeductions}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {activeFooter?.total && (
          <>
            <Row className="m-0">
              <Col className="col-12 col-sm-4 title">{activeFooter?.total?.totalLabel}</Col>
              <Col className="col-12 col-sm-8 columnthree">
                <Row className="justify-content-between valueboxes">
                  <Col>&nbsp;</Col>
                  <Col>&nbsp;</Col>
                  {employerCostLabel && creditsAppliedLabel && <Col>&nbsp;</Col>}
                  <Col className="last-column">
                    <span className="visuallyhidden">{deductionLabel}</span>
                    {activeFooter?.total?.deductions}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
      <ModalComponent modalObject={modalObject}>{notesList}</ModalComponent>
    </div>
  );
};
export default SummaryActiveSubscriptions;
