import { useEffect, useState } from "react";
import { dissmiss } from "../../actions";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
//import warningnew from "../../assets/icons/warningnew.svg";

/*Note: need to add session time out logic after session imp.   */
export const AlertComponent = (props: any) => {
  const { message, messageType } = useSelector((state: RootState) => state.status);

  const { propmessage, propmessage1, propmessageclass, heading, iconImage, closeIcon, propmessageType, marginleft, notBootStrapClass, onDismiss } = props;
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleDismiss = (closeFlag: number) => {
    if (closeFlag === 1) dissmiss();
    setShowAlert(false);
    if (onDismiss) {
      onDismiss();
    }
  };
  useEffect(() => {
    propmessage && setShowAlert(true);
  }, [propmessage]);

  return (
    <>
      {message && !showAlert && (
        <div className={`alert alert-${messageType}${closeIcon ? " alert-dismissible" : ""}`} role="alert">
          {messageType && <h4 className="alert-heading">{heading}</h4>}
          <div className="d-flex">
            <p
              dangerouslySetInnerHTML={{
                __html: message
              }}
            />
          </div>
          {closeIcon && <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => handleDismiss(1)}></button>}
        </div>
      )}

      {showAlert && (
        <div
          className={`${notBootStrapClass ? propmessageType : propmessageType === "error" ? `p-0 alert alert-${propmessageType}` : `alert alert-${propmessageType}`}${
            closeIcon ? " alert-dismissible" : ""
          }`}
          role="alert"
        >
          {heading && <h4 className="alert-heading">{heading}</h4>}
          <div className="d-flex">
            {iconImage && <img src={iconImage} alt="" />}
            <span
              className={`${propmessageType === "error" ? "error-messages mt-2" : ""} ${marginleft ? "ms-2" : ""} ${propmessageclass ? propmessageclass : ""}`}
              dangerouslySetInnerHTML={{
                __html: propmessage
              }}
            />
          </div>
          {propmessage1 && <p className=" m-0">{propmessage1}</p>}
          {closeIcon && <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => handleDismiss(0)}></button>}
        </div>
      )}
    </>
  );
};

export default AlertComponent;
