import { ButtonComponent, ChoiceListComponent } from "..";
import { useSelector, useDispatch } from "react-redux";
import { ObjectUtils } from "../../utils";
import { RootState } from "../../reducers";
import { useState, useEffect } from "react";
import { saveBenefitsWithSmoker } from "../../actions/benefitActions";
//import {showError } from "../../actions";

export type smokerOptions = {
  smokerStatus: number;
  isEmployeeSmokerStatusRequired: boolean;
  isDependentSmokerStatusRequired: boolean;
  IsSmokerStatusUpdate: boolean;
};

export const DeclareSmokerStatusComponent = (props) => {
  const dispatch = useDispatch();
  const { isEmployeeSmokerStatusRequired, smokerStatus, showSmokerContent, handleCancel } = props;
  const { benefitsLists, benefitUrlList } = useSelector((state: RootState) => state.benefit);
  const [sessionSmokerOptions, setsessionSmokerOptions] = useState<smokerOptions>({
    smokerStatus: smokerStatus,
    isEmployeeSmokerStatusRequired: false,
    isDependentSmokerStatusRequired: false,
    IsSmokerStatusUpdate: false
  });

  useEffect(() => {
    setsessionSmokerOptions({ smokerStatus: smokerStatus, isEmployeeSmokerStatusRequired: false, isDependentSmokerStatusRequired: false, IsSmokerStatusUpdate: false });
  }, []);

  const changeSmokerStatus = (callback: any) => {
    const smokerType: any = Object.keys(callback)[0]?.split("_")[0];
    const value: any = Object.values(callback)[0];
    const smokerOptions: any = { ...sessionSmokerOptions };
    smokerOptions["smokerStatus"] = parseInt(value);
    if (smokerType === "employeeSmokerStatus") smokerOptions["isEmployeeSmokerStatusRequired"] = true;
    else if (smokerType === "dependentSmokerStatus") smokerOptions["isDependentSmokerStatusRequired"] = true;
    smokerOptions["IsSmokerStatusUpdate"] = true;
    setsessionSmokerOptions(smokerOptions);
  };

  const declareSmokerStatus = () => {
    const benefitWithOptions: any = [];
    let benefitOptions: any = {};

    for (const [index, benefit] of benefitsLists.entries()) {
      const selectedBenefitOption: any = {};

      const [benefitName, benefitid] = benefitUrlList[index].split("/");
      const optionList = benefitName.toLowerCase() === "package" ? benefit.packageList : benefit.options;
      // const selectedOption = _.where(optionList, { isSelected: true });
      const selectedOption = optionList.filter((option: { isSelected: boolean }) => option.isSelected);
      const optionID = selectedOption.length ? selectedOption[0].optionID : "";
      const reqParamProp = (benefitName.toLowerCase() === "package" && "packageGroupID") || "benefitID";
      if (benefitid && optionID) {
        selectedBenefitOption[reqParamProp] = benefitid;
        selectedBenefitOption["optionID"] = optionID;
      } else {
        selectedBenefitOption["benefitID"] = 100 + benefitid;
      }
      benefitWithOptions.push(selectedBenefitOption);
    }

    benefitOptions = { ...sessionSmokerOptions, benefits: [...benefitWithOptions] };

    dispatch(saveBenefitsWithSmoker(benefitOptions));
    handleCancel();
  };

  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents;
  const btnCancel = ObjectUtils.extractLabelFromObject(footerContent || {}, "btnCancel");
  const btnDeclare = ObjectUtils.extractLabelFromObject(footerContent || {}, "btnDeclare");
  const { lblIsSmoker, lblIsNonSmoker, smokerDeclarationLabel, smokerStatusList }: any = showSmokerContent || "";
  let smokerStatusMessage: any = "";
  if (["0", 0].indexOf(smokerStatus) > -1) smokerStatusMessage = lblIsNonSmoker;
  if (["1", 1].indexOf(smokerStatus) > -1) smokerStatusMessage = lblIsSmoker;

  const buttonInput = [
    {
      variant: "btn btn-outline-secondary btn-save",
      display: btnCancel?.display,
      onSubmit: handleCancel
    },
    {
      variant: "btn btn-secondary btn-save",
      display: btnDeclare?.display,
      onSubmit: declareSmokerStatus
    }
  ];

  //Benefit option
  const optionsInput = {
    name: `${isEmployeeSmokerStatusRequired ? "employeeSmokerStatus" : "dependentSmokerStatus"}`,
    fgclass: "mt-2 mb-4 w-50 float-left",
    legendtext: smokerStatusMessage,
    selected: sessionSmokerOptions?.smokerStatus.toString(),
    handlechange: changeSmokerStatus,
    optionsinput: smokerStatusList
  };
  //Benefit option End
  return (
    <div className={`changedependentcoverage clearfix`}>
      <ChoiceListComponent choicelistdata={optionsInput} />
      {sessionSmokerOptions?.smokerStatus === 0 && smokerDeclarationLabel ? (
        <>
          <div role="alert" className="alert alert-primary" dangerouslySetInnerHTML={{ __html: smokerDeclarationLabel }} />
        </>
      ) : (
        ""
      )}
      <div className="ml-popupbuttons d-flex border-top pt-3 pt-md-4 justify-content-between">{buttonInput && <ButtonComponent btndata={buttonInput} />}</div>
    </div>
  );
};
export default DeclareSmokerStatusComponent;
