import { useDispatch } from "react-redux";
import { saveWaiverForm } from "../../actions/benefitActions";
import { ObjectUtils } from "../../utils";
import { useState, Fragment, useRef, useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";
import { ButtonComponent, DescriptionComponent, TextFieldComponent, ChoiceListComponent } from "..";
import _ from "underscore";

export type sessionMedicalWaiver = {
  waiverReason: string;
  insuranceCompany: string;
  policyNumber: string;
  formValidated: boolean;
};

const _waiverBenefits: any = [
  {
    isSelected: false,
    waiverReason: "",
    insuranceCompany: "",
    policyNumber: ""
  }
];
export const MedicalWaiverComponent = (props) => {
  const { isPackage, benefit, type, benefitURL, handleCancel, selectedOption, isOptOut, showAlternateCoverageDetails } = props;
  const { waiverBenefits: propWaiverBenefits, optOutWaiverBenefits } = benefit;
  const dispatch = useDispatch();
  const textInputRefs = useRef<HTMLInputElement[]>([]);
  const [sessionMedicalWaiver, setSessionMedicalWaiver] = useState<any>();

  const handlecheckbox = useCallback(
     () => {
      const { waiverBenefits } = sessionMedicalWaiver;
      _.each(waiverBenefits, (item,index) => {
        if(showAlternateCoverageDetails){
        waiverBenefits[index].isSelected=true;
        }
        else if(item.waiverReason.value.indexOf("_PACKAGEDEPENDENTWAIVER")>0)
        {
          waiverBenefits[index].isSelected=true;
        }
      });
      setSessionMedicalWaiver({ ...sessionMedicalWaiver, ...waiverBenefits });
    },
    [sessionMedicalWaiver, showAlternateCoverageDetails]
  );
  
  useEffect(() => {
    let optOutBenefits: any = null;
    if (isPackage && optOutWaiverBenefits && isOptOut) optOutBenefits = (optOutWaiverBenefits?.length && optOutWaiverBenefits) || [optOutWaiverBenefits];
    const waveOptionsOrBenefits = isPackage ? (selectedOption && selectedOption?.showWaiveOption && optOutBenefits) || propWaiverBenefits : _waiverBenefits;
    const waiverBenefits: any =
      waveOptionsOrBenefits &&
      waveOptionsOrBenefits.map((item) => ({
        ...item,
        isSelected: (waveOptionsOrBenefits && waveOptionsOrBenefits.length === 1) || item.isSelected,
        waiverReason: {
          value: item.waiverReason || "",
          hasError: !item.waiverReason
        },
        insuranceCompany: {
          value: item.insuranceCompany || "",
          hasError: !item.insuranceCompany
        },
        policyNumber: {
          value: item.policyNumber || "",
          hasError: !item.policyNumber
        }
      }));
    setSessionMedicalWaiver({ formValidated: false, formSubmitted: false, waiverBenefits, selectedOption });
  }, []);


  useEffect(() => {
    sessionMedicalWaiver && isPackage &&  handlecheckbox();
  }, [handlecheckbox, isPackage, sessionMedicalWaiver]);

  const onInputChange = (index: number, callback: any) => {
    const { waiverBenefits } = sessionMedicalWaiver;
    const name: any = Object.keys(callback)[0];
    const value: any = Object.values(callback)[0];
    waiverBenefits[index][name]["value"] = value;
    waiverBenefits[index][name]["hasError"] = !value;
    setSessionMedicalWaiver({ ...sessionMedicalWaiver, ...waiverBenefits });
  };

  const handleCheckboxChange = (index: any) => {
    const { waiverBenefits } = sessionMedicalWaiver;
    waiverBenefits[index].isSelected = !waiverBenefits[index].isSelected;
    setSessionMedicalWaiver({ ...sessionMedicalWaiver, ...waiverBenefits });
  };

  const isInvalid = (item: any, fieldName: string) => {
    const { formSubmitted } = sessionMedicalWaiver;
    return formSubmitted && item?.isSelected && item[fieldName]?.hasError;
  };

  const submitHandler = (event: any) => {
    const form = event.currentTarget;
    event.preventDefault();
    
    if (isPackage) {
      const { waiverBenefits } = sessionMedicalWaiver;

      let isValid: any = true;
      waiverBenefits?.forEach((item) => {
        const { waiverReason, insuranceCompany, policyNumber, isSelected } = item;
        const isFieldValid = !waiverReason.hasError && !insuranceCompany.hasError && !policyNumber.hasError;
        if (isSelected && !isFieldValid) isValid = false;
      });
      console.log("isValid> ", isValid);

      if (!isValid) {
        setControlFocus();
        return setSessionMedicalWaiver({
          waiverBenefits,
          formValidated: false,
          formSubmitted: true
        });
      }
      setSessionMedicalWaiver({ ...sessionMedicalWaiver, formValidated: true, formSubmitted: true });
      handleSaveForm();
      handleCancel();
    } else {
      console.log("form.checkValidity(): ", form.checkValidity());
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        handleSaveForm();
        handleCancel();
      }
      setSessionMedicalWaiver({ ...sessionMedicalWaiver, formValidated: true });
    }
  };

  const handleSaveForm = () => {
    const { waiverBenefits, selectedOption } = sessionMedicalWaiver;
    const packageWaiverObj = waiverBenefits?.map((item) => ({
      optionID: selectedOption?.optionID,
      benefitID: item.benefitID,
      benefitName: item.benefitName,
      isSelected: isPackage && showAlternateCoverageDetails?true: item.isSelected,
      waiverReason:isPackage?( !showAlternateCoverageDetails? item.waiverReason.value.replace('_PACKAGEDEPENDENTWAIVER',''):(item.waiverReason.value.indexOf("_PACKAGEDEPENDENTWAIVER")<=-1?item.waiverReason.value+'_PACKAGEDEPENDENTWAIVER':item.waiverReason.value)):item.waiverReason.value,
      insuranceCompany: item.insuranceCompany.value,
      policyNumber: item.policyNumber.value
    }));

    const waiverOptOutObj = packageWaiverObj?.map(({ optionID, benefitID, waiverReason, insuranceCompany, policyNumber }) => ({
      optionID,
      benefitID,
      waiverReason,
      insuranceCompany,
      policyNumber
    }));
    
    const _type: any = isOptOut || (!isPackage && (showAlternateCoverageDetails ||!type))? true : false;

    if (benefitURL) dispatch(saveWaiverForm(_type, _type ? waiverOptOutObj : packageWaiverObj, benefitURL));
    else dispatch(saveWaiverForm(_type, _type ? waiverOptOutObj : packageWaiverObj));
  };

  const setControlFocus = () => {
    const { waiverBenefits } = sessionMedicalWaiver;
    waiverBenefits.map((item: any, index: number) => {
      const { waiverReason, insuranceCompany, policyNumber, isSelected } = item;
      if (isSelected) {
        if (!showAlternateCoverageDetails && !waiverReason.value) {
          textInputRefs.current[index]?.focus();
        } else if (!insuranceCompany.value) {
          textInputRefs.current[index + 1]?.focus();
        } else if (!policyNumber.value) {
          textInputRefs.current[index + 2]?.focus();
        }
      }
    });
  };

  const contents = benefit?.contentResource?.contents;
  const lblPlanStartDate = benefit.lblPlanStartDate;
  const btnSave = ObjectUtils.extractLabelFromObject(contents, "btnSave");
  const btnCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const lblDependentCoverageWaiverHeading = ObjectUtils.extractLabelFromObject(contents, "lblDependentCoverageWaiverHeading");
  const lblWaiveCoverageDialogDescription = ObjectUtils.extractLabelFromObject(contents, (isOptOut && "lblWaiveOptionDialogDescription") || "lblWaiveCoverageDialogDescription");
  const lblWaiveCoverageDialogPlanStartDate = ObjectUtils.extractLabelFromObject(contents, "lblWaiveCoverageDialogPlanStartDate");
  const lblWaiveCoverageDialogReason = ObjectUtils.extractLabelFromObject(contents, isPackage ? "lblReason" : "lblWaiveCoverageDialogReason");
  const lblWaiveCoverageDialogForProof = ObjectUtils.extractLabelFromObject(contents, "lblWaiveCoverageDialogForProof");
  const lblWaiveCoverageDialogInsuranceCompany = ObjectUtils.extractLabelFromObject(contents, isPackage ? "lblInsuranceCompany" : "lblWaiveCoverageDialogInsuranceCompany");
  const lblWaiveCoverageDialogPolicy = ObjectUtils.extractLabelFromObject(contents, isPackage ? "lblPolicy" : "lblWaiveCoverageDialogPolicy");
  const lblWaiveCoverageDialogNote = ObjectUtils.extractLabelFromObject(contents, "lblWaiveCoverageDialogNote");

  const lblWaiveOptionDialogDescription = ObjectUtils.extractLabelFromObject(contents, "lblWaiveOptionDialogDescription");
  const lblWaiveOptionDialogReason = ObjectUtils.extractLabelFromObject(contents, "lblWaiveOptionDialogReason");
  const lblWaiveOptionDialogProofCoverage = ObjectUtils.extractLabelFromObject(contents, "lblWaiveOptionDialogProofCoverage");
  const lblWaiveOptionDialogCompany = ObjectUtils.extractLabelFromObject(contents, "lblWaiveOptionDialogCompany");
  const lblWaiveOptionDialogPolicy = ObjectUtils.extractLabelFromObject(contents, "lblWaiveOptionDialogPolicy");
  const lblWaiveOptionDialogNote = ObjectUtils.extractLabelFromObject(contents, "lblWaiveOptionDialogNote");
  const lblAlternateCoverageDetailsNote = ObjectUtils.extractLabelFromObject(contents, "lblAlternateCoverageDetailsNote");
  const rfvtxtWaiveCoverageReasonErrorMessage = ObjectUtils.extractLabelFromObject(contents, isPackage ? "rfvtxtReason" : "rfvtxtWaiveCoverageReasonErrorMessage");
  const rfvtxtWaiveCoverageInsuranceCompanyErrorMessage = ObjectUtils.extractLabelFromObject(contents, isPackage ? "rfvtxtInsuranceCompany" : "rfvtxtWaiveCoverageInsuranceCompanyErrorMessage");
  const rfvtxtWaiveCoveragePolicyErrorMessage = ObjectUtils.extractLabelFromObject(contents, isPackage ? "rfvtxtPolicy" : "rfvtxtWaiveCoveragePolicyErrorMessage");
  const rfvtxtWaiveOptionDialogReasonErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvtxtWaiveOptionDialogReasonErrorMessage");
  const rfvtxtWaiveOptionDialogCompanyErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvtxtWaiveOptionDialogCompanyErrorMessage");
  const rfvtxtWaiveOptionDialogPolicyErrorMessage = ObjectUtils.extractLabelFromObject(contents, "rfvtxtWaiveOptionDialogPolicyErrorMessage");
  const listgridRowData: any = [];
  sessionMedicalWaiver?.waiverBenefits?.map((item: any, index: number) => {
    const gridRowData: any = [
      isPackage && !showAlternateCoverageDetails && sessionMedicalWaiver?.waiverBenefits.length > 1
        ? {
            type: "choicelist",
            colclassname: "col-sm-12 col-md-12",
            mode: "edit",
            options: {
              type: "checkbox",
              ischecked: true,
              fgclass: "mt-2 mb-4",
              className: "fw-bold",
              width: 100,
              name: `isChkbenefitName_${index}`,
              selected: item.isSelected,
              isCallbackParam: true,
              optionsinput: [
                {
                  text: item?.benefitName,
                  value: true
                }
              ],
              handlechange: () => handleCheckboxChange(index)
            }
          }
        : isPackage &&
          item?.benefitName && {
            type: "text",
            mode: "view",
            display: `<b>${item?.benefitName}</b>`,
            gridrow: "col-md-9 col-6",
            fgclass: "mb-3 mt-3 row"
          },
      !isPackage &&
        (type || showAlternateCoverageDetails) && {
          type: "text",
          name: "planStartDate",
          texttoread: lblWaiveCoverageDialogPlanStartDate.texttoread,
          display: lblWaiveCoverageDialogPlanStartDate.display,
          mode: "edit",
          value: lblPlanStartDate,
          gridrow: "col-md-9 col-6",
          fgclass: "mb-3 row",
          lblbpfixclassname: "col-md-3 col-6 col-form-label",
          classname: "form-control-plaintext m-0",
          readonly: true
        },
      ((isPackage && showAlternateCoverageDetails) || !showAlternateCoverageDetails) && {
        type: "text",
        name: "waiverReason",
        texttoread: type || isPackage ? lblWaiveCoverageDialogReason.textToRead : lblWaiveOptionDialogReason.textToRead,
        display: lblWaiveCoverageDialogReason.display,
        mode: "edit",
        value: item?.waiverReason?.value?.indexOf("_PACKAGEDEPENDENTWAIVER") >= 0 ? item?.waiverReason?.value?.replace("_PACKAGEDEPENDENTWAIVER", "") : item?.waiverReason?.value,
        gridrow: "col-md-9 col-6 ml-inputfields",
        fgclass: "mb-3 row mb-md-4 rowgroup",
        lblbpfixclassname: "col-md-3 col-6 col-form-label",
        classname: "form-control m-0",
        error: item?.waiverReason?.hasError && (isPackage || type ? rfvtxtWaiveCoverageReasonErrorMessage.display : rfvtxtWaiveOptionDialogReasonErrorMessage.display),
        handlechange: (event: any) => onInputChange(index, event),
        isInvalid: isInvalid(item, "waiverReason"),
        //onInputChange,
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[index] = ref)
      },
      !isPackage &&
        !showAlternateCoverageDetails && {
          type: "heading",
          gridrow: "mb-3 row",
          level: 5,
          className: "m-0 fw-bold waiversubtitle",
          heading: type ? lblWaiveCoverageDialogForProof.display : lblWaiveOptionDialogProofCoverage.display
        },
      {
        type: "text",
        name: "insuranceCompany",
        texttoread: type || isPackage ? lblWaiveCoverageDialogInsuranceCompany.textToRead : lblWaiveOptionDialogCompany.textToRead,
        display: type || isPackage ? lblWaiveCoverageDialogInsuranceCompany.display : lblWaiveOptionDialogCompany.display,
        mode: "edit",
        value: item?.insuranceCompany?.value,
        gridrow: "col-md-9 col-6 ml-inputfields",
        fgclass: "mb-3 row",
        lblbpfixclassname: "col-md-3 col-6 col-form-label",
        classname: "form-control m-0",
        error: item?.insuranceCompany?.hasError && (type || isPackage ? rfvtxtWaiveCoverageInsuranceCompanyErrorMessage.display : rfvtxtWaiveOptionDialogCompanyErrorMessage.display),
        isInvalid: isInvalid(item, "insuranceCompany"),
        handlechange: (event: any) => onInputChange(index, event),
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[index + 1] = ref)
      },
      {
        type: "text",
        name: "policyNumber",
        texttoread: type || isPackage ? lblWaiveCoverageDialogPolicy.textToRead : lblWaiveOptionDialogPolicy.textToRead,
        display: type || isPackage ? lblWaiveCoverageDialogPolicy.display : lblWaiveOptionDialogPolicy.display,
        mode: "edit",
        value: item?.policyNumber?.value,
        gridrow: "col-md-9 col-6 ml-inputfields",
        fgclass: "mb-3 row",
        lblbpfixclassname: "col-md-3 col-6 col-form-label",
        classname: "form-control m-0",
        isInvalid: isInvalid(item, "policyNumber"),
        error: item?.policyNumber?.hasError && (type || isPackage ? rfvtxtWaiveCoveragePolicyErrorMessage.display : rfvtxtWaiveOptionDialogPolicyErrorMessage.display),
        handlechange: (event: any) => onInputChange(index, event),
        refInput: (ref: HTMLInputElement) => (textInputRefs.current[index + 2] = ref)
      }
    ];
    listgridRowData.push(gridRowData);
  });

  const buttonInput = [
    btnCancel.display && {
      variant: "outline-secondary btn-cancel",
      display: btnCancel.display,
      onSubmit: handleCancel
    },
    btnSave.display && {
      type: "submit",
      variant: "secondary btn-submit float-end",
      display: btnSave.display
    }
  ];

  return (
    <>
      <div className={"medicalwaiver"}>
        <div className="ml-description mb-md-3">
          <p
            className={`m-0`}
            dangerouslySetInnerHTML={{ __html: ((type || (isPackage && !showAlternateCoverageDetails)) && lblWaiveCoverageDialogDescription.display) ||
              (isPackage && showAlternateCoverageDetails && lblDependentCoverageWaiverHeading.display) ||
              ((!type || (!isPackage && !showAlternateCoverageDetails)) && lblWaiveOptionDialogDescription.display) ||
              (!isPackage && showAlternateCoverageDetails && lblAlternateCoverageDetailsNote.display) }}
          />
        </div>
        <Form noValidate validated={sessionMedicalWaiver?.formValidated} onSubmit={submitHandler}>
          {/* listgridRowData?.map((item: any, index: number) => {
              return item && "";
            }) */}
          {listgridRowData?.map((gridRowData: any, index: number) => {
            return (
              <Fragment key={index}>
                {gridRowData?.map((item: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      {item?.type === "text" && <TextFieldComponent inputdata={[item]} />}
                      {item?.type === "choicelist" && <ChoiceListComponent choicelistdata={item?.options} />}
                      {item?.type === "heading" && (
                        <div className={item.gridrow}>
                          <DescriptionComponent level={item.level} headingclassname={item.className} heading={item.heading} />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
          <DescriptionComponent classname={`m-0`} description={type || isPackage ? lblWaiveCoverageDialogNote.display : lblWaiveOptionDialogNote.display} />
          <div className="ml-popupbuttons d-flex border-top pt-3 pt-md-4 justify-content-between">{buttonInput && <ButtonComponent btndata={buttonInput} />}</div>
        </Form>
      </div>
    </>
  );
};
export default MedicalWaiverComponent;
