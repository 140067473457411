import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cancelEnrollmentClick, dissmiss, focusInitializing, getEnrollmentFooter, proceedToNext, proceedToPrevious, setMiniCartInitialState, setPageSetting } from "../../actions";
import { navigateChangedState } from "../../constants";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { ModalComponent } from "../Modal";
import ButtonComponent from "./ButtonComponent";
// import { useEffect } from "react";
// import ButtonComponent from "./ButtonComponent";

interface DisplayModelState {
  showModal: boolean;
  showWarningModal: boolean;
}

export const NextPrevComponent = (props: any) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayModel, setDisplayModel] = useState<DisplayModelState>({
    showModal: false,
    showWarningModal: false
  });
  const { contentFooter, clientDetails, screens } = useSelector((state: RootState) => state.common);

  const { enrolmentFooter, pageSetting, disableNext } = useSelector((state: RootState) => state.event);
  const footerContent = contentFooter?.contentResource?.contents;
  const footerEnrollment = enrolmentFooter?.contentResource?.contents;

  const nextButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "btnNext");
  const previousButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "btnPrevious");
  const cancelButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const homeButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "lblHome");
  // Enrollment
  const lblCancelText = ObjectUtils.extractLabelFromObject(footerEnrollment, "lblCancelText");
  const lblCancelTextSummary = ObjectUtils.extractLabelFromObject(footerEnrollment, "lblCancelTextSummary");

  const yesButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "lblYes");
  const noButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "lblNo");

  /* const closeButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnClose"); */

  const cancelHandler = (event: { currentTarget: { blur: () => void } }) => {
    if (props?.cancelClicked) props?.cancelClicked();
    else {
      getEnrollmentFooter(() => {
        setDisplayModel({
          ...displayModel,
          showModal: true
        });
      });
    }
    // navigate("/", navigateChangedState);
    event.currentTarget.blur();
  };

  const handleClose = () => {
    setDisplayModel({
      ...displayModel,
      showModal: false
    });
  };

  const handleCancel = async () => {
    await cancelEnrollmentClick(navigate, null);
    await setMiniCartInitialState(false);
    navigate("/", navigateChangedState);
    // await enableApiLoader();
    // skipMainContent();
  };

  const handleNext = async (event: any) => {
    dissmiss();

    if (props?.isSmoker) {
      props?.isSmokerCallBack();
    } else {
      // console.log(props?.nextClick);
      if (props?.nextClick) props?.nextClick();
      else {
        const location = props?.location || "";
        proceedToNext(navigate, location, "");
        props?.onClick();
      }
      event?.currentTarget?.blur();
      setMiniCartInitialState(false);
      focusInitializing();
    }
  };

  const handelGoPrevBtn = (event: any) => {
    dissmiss();
    if (props?.previousClick) {
      props?.previousClick();
    } else {
      proceedToPrevious(navigate, "");
      props?.onClick();
    }
    event?.currentTarget?.blur();
    setMiniCartInitialState(false);
    focusInitializing();
  };

  const handlePrevious = (event: any) => {
    const showWarningMessage = clientDetails?.showWarningMessage ?? false;
    if (showWarningMessage) {
      const index = screens.findIndex((item: { name: any }) => item.name === pageSetting?.name);
      const prevPageName = screens[index - 1].link;
      const currPageName = screens[index].link;
      if (currPageName !== "Wellness" && (prevPageName.includes("Dependent") || prevPageName.includes("Wellness")))
        setDisplayModel({
          ...displayModel,
          showWarningModal: true
        });
      else handelGoPrevBtn(event);
    } else {
      handelGoPrevBtn(event);
    }
  };

  const handleGoHome = () => {
    props?.onClick && props?.onClick();
    setPageSetting("WELCOME");
    navigate("/", navigateChangedState);
  };

  const buttonInput = [
    !props?.hideCancel && {
      variant: "outline-secondary btn-cancel float-left",
      display: props?.lblCancel ? props?.lblCancel.display : nextButtonLabel.display,
      name: "cancelbtn",
      onSubmit: cancelHandler
    },
    !props?.firstPage &&
      pageSetting?.link !== "Profile" && {
        variant: "outline-secondary btn-previous",
        display: props.lblPrevious ? props?.lblPrevious.display : previousButtonLabel.display,
        name: "previousBtn",
        onSubmit: handlePrevious
      },
    !props.lastPage && {
      variant: "outline-secondary btn-next",
      display: props.lblNext ? props?.lblNext.display : cancelButtonLabel.display,
      name: "btnGoNext",
      disabled: props?.nextDisabled || disableNext,
      onSubmit: handleNext
    }
  ];

  const buttonGoHome = [
    {
      variant: "outline-secondary btn-home",
      display: homeButtonLabel.display,
      name: "goHomeBtn",
      onSubmit: handleGoHome
    }
  ];

  const cancelEnrollment = {
    showModal: displayModel?.showModal,
    clickHandler: handleClose,
    head: {
      modalTitle: lblCancelText?.display
    },
    body: {
      confirmBoxData: {
        messagetext: lblCancelTextSummary?.display,
        messagepopclass: "ml-popupmessage",
        messagetextclass: "ml-popupmessagetext"
      }
    },
    footer: {
      footerData: [
        {
          variant: "outline-secondary btn-cancel",
          display: noButtonLabel?.display,
          onSubmit: handleClose
        },
        {
          variant: "outline-secondary",
          display: yesButtonLabel?.display,
          onSubmit: handleCancel
        }
      ]
    }
  };

  /* useEffect(() => {
    proceedToNext(navigate);
  }, [navigate]); */

  return (
    <>
      {localStorage.getItem("hideButtons") || props?.showButtons ? (
        <div className="review-card-btns ml-btns border-top">
          <ButtonComponent btndata={buttonInput} />
        </div>
      ) : (
        /* !props.lastPage && <ButtonComponent btndata={buttonGoHome} /> */
        <ButtonComponent btndata={buttonGoHome} />
      )}
      <ModalComponent modalObject={cancelEnrollment}></ModalComponent>
    </>
  );
};

export default NextPrevComponent;
