import { faChevronDown, faChevronUp, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { getCustomPopUpURL, setFlexBeneficiaryBenefitID,setMultiBeneficiariesList } from "../../actions";
import { removeEOIOption, removeWaiver, setMultiBenefitOption } from "../../actions/benefitActions";
import {
  AlertComponent,
  ButtonComponent,
  CardComponent,
  ChangeDependentCoverageComponent,
  ChoiceListComponent,
  DeclareSmokerStatusComponent,
  DescriptionComponent,
  ModalComponent,
  TableComponent,
  UnitCoverageComponent
} from "../../components";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import { MedicalWaiverComponent } from "./MedicalWaiverComponent";
import { useNavigate } from "react-router-dom";
import { TooltipIconComponent } from "../Icon";

export type ShowModal = {
  showHelpContent: boolean;
  showDesc: boolean;
  showChangeDependentCoverage: boolean;
  showMedicalWaiver: boolean;
  showMedicalWaiverDependent: boolean;
  showAlternateCoverageDetails: boolean;
  expandSmokerBlock: boolean;
};

export type BenefitTooltipDesc = {
  showDesc: boolean;
  description: string;
  toolTipId: string;
  toolTipHeader: string;
};

export const ShellComponent = (props: any) => {
  const dispatch = useDispatch();
  const { benefit, benefitName, benefitURL, smokerContent, benefitID } = props;
  const [selectedOption, setSelectedOption] = useState<any>();
  const [, setselected] = useState<any>();
  const [options, setoptions] = useState<any>();
  const [isExpanded, setisExpanded] = useState<boolean>(false);
  const [collapsedOptionList, setcollapsedOptionList] = useState<any>();
  const navigate = useNavigate();
  const [showbenefitToolTipDesc, setbenefitToolTipDesc] = useState<BenefitTooltipDesc>({
    showDesc: false,
    description: "",
    toolTipId: "",
    toolTipHeader: ""
  });
  const [showModal, setShowModal] = useState<ShowModal>({
    showHelpContent: false,
    showDesc: false,
    showChangeDependentCoverage: false,
    showMedicalWaiver: false,
    showMedicalWaiverDependent: false,
    showAlternateCoverageDetails: false,
    expandSmokerBlock: false
  });

  const helpContentResponse = useSelector((state: RootState) => state.common.helpContentResponse || null);

  useEffect(() => {
    const options = benefitName === "package" ? benefit?.packageList : benefit?.options || [];
    setoptions(options);
    const selectedOption = _.where(options, { isSelected: true });
    const selected = selectedOption?.length ? selectedOption[0]?.optionID : "";
    const selectedOptionProp = options ? options?.find((opt) => opt?.optionID === selected) : {};
    setselected(selected);
    setSelectedOption(selectedOptionProp);
    if ((options || [])?.length > 10) makeCollapsedOptions(options, selectedOptionProp);
  }, [benefit?.options, benefit?.packageList, benefitName]);
  const toggleDescription = (opt: any, tooltipIdValue: string) => {
    setbenefitToolTipDesc({
      showDesc: !showbenefitToolTipDesc?.showDesc,
      description: opt?.toolTipDescription,
      toolTipHeader: opt?.toolTipHeader,
      toolTipId: tooltipIdValue
    });
  };
  const makeCollapsedOptions = (options: any, selected: any) => {
    const selectedIndex = options?.findIndex((opt) => opt === selected); //7047
    const eoiOptionIndex = options?.findIndex((opt) => opt?.requireEOI === true);
    let collapsedOptionList: any = [];
    if (selectedIndex === 0) collapsedOptionList = options?.slice(selectedIndex, selectedIndex + 3);
    else if (selectedIndex === options?.length - 1) collapsedOptionList = options?.slice(selectedIndex - 2);
    else collapsedOptionList = options?.slice(selectedIndex - 1, selectedIndex + 2);
    if (eoiOptionIndex !== -1) {
      const collapsedEOIIndex = collapsedOptionList?.findIndex((opt) => opt?.requireEOI === true);
      if (collapsedEOIIndex === -1) collapsedOptionList.push(options[eoiOptionIndex]);
    }
    setcollapsedOptionList(collapsedOptionList);
  };

  const setValue = (option: any) => {
    const _options: any = [...options];
    _options.forEach((opt) => {
      opt.isSelected = opt.optionID === option.optionID;
    });
    if ((options || [])?.length > 10 && isExpanded) makeCollapsedOptions(options, option);
    setisExpanded(false);
    setoptions(_options);
    setselected(option.optionID);
    dispatch(setMultiBenefitOption(benefitURL, option.optionID, true));
  };
  const toggleExpandCollapse = () => {
    setisExpanded(!isExpanded);
  };

  const handleRemoveWaiver = () => {
    dispatch(removeWaiver(benefitURL));
  };

  const handleRemoveEOI = () => {
    dispatch(removeEOIOption(benefitURL));
  };
  const contents = benefit?.contentResource?.contents || [];
  const requiresOtherCoverage = selectedOption?.requiresOtherCoverage || false;
  const provincesRequiringOtherCoverage = selectedOption?.provincesRequiringOtherCoverage || false;
  const lblViewBeneficiaries = ObjectUtils.extractLabelFromObject(contents, "lblViewBeneficiaries");
  const lblChangeDependents = ObjectUtils.extractLabelFromObject(contents, "lblChangeDependents");
  const lblWaiveCoverage = ObjectUtils.extractLabelFromObject(contents, "lblWaiveCoverage");
  const lblRemoveEOI = ObjectUtils.extractLabelFromObject(contents, "lblRemoveEOI");
  const lblDependentsCoveredUnderThisPlan = ObjectUtils.extractLabelFromObject(contents, "lblDependentsCoveredUnderThisPlan");
  const lblWaiveOptionDialogTitle = ObjectUtils.extractLabelFromObject(contents, (benefitName !== "package" && "lblWaiveOptionDialogTitle") || "lblWaiveCoverageDialogTitle");
  // const lblWaiverNoteOptOut = ObjectUtils.extractLabelFromObject(contents, "lblWaiverNoteOptOut");
  const lblOtherCoverageRequiredNote = ObjectUtils.extractLabelFromObject(contents, "lblOtherCoverageRequiredNote");
  const lblOtherCoverageRequiredForProvince = ObjectUtils.extractLabelFromObject(contents, "lblOtherCoverageRequiredForProvince");
  const lblRemoveWaiver = ObjectUtils.extractLabelFromObject(contents, "lblRemoveWaiver");
  const lblSeeDetails = ObjectUtils.extractLabelFromObject(contents, "lblSeeDetails");
  const unitCoverageMessage = ObjectUtils.extractLabelFromObject(contents, "unitCoverageMessage");
  const lblAlternateCoverageDetailsTitle = ObjectUtils.extractLabelFromObject(contents, "lblAlternateCoverageDetailsTitle");
  const lblDependentCoverageWaiverTitle = ObjectUtils.extractLabelFromObject(contents, "lblDependentCoverageWaiverTitle");
  const lblAlternateCoverageDetails = ObjectUtils.extractLabelFromObject(contents, "lblAlternateCoverageDetails");
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const lnkMore = ObjectUtils.extractLabelFromObject(footerContent, "lnkMore");
  const lnkLess = ObjectUtils.extractLabelFromObject(footerContent, "lnkLess");
  const toolTipIcon = ObjectUtils.extractLabelFromObject(footerContent, "ToolTipIcon");
  const lblEOINote = ObjectUtils.extractLabelFromObject(contents, "lblEOINote");
  const lblEOITitle = ObjectUtils.extractLabelFromObject(contents, "lblEOINoteTitle");
  // const closeButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnClose");
  const employeeSmoker = smokerContent?.employeeSmoker || null;
  const employeeErrorMsg = (benefit?.isEmployeeSmokerStatusRequired && benefit?.smokerStatus === 2 && employeeSmoker?.lblErrorMessage) || null;
  const dependentSmoker = smokerContent?.dependentSmoker || null;
  const dependentErrorMsg = (benefit?.isDependentSmokerStatusRequired && benefit?.smokerStatus === 2 && dependentSmoker?.lblErrorMessage) || null;

  let showSmokerContent: any = "";
  if (benefit?.isEmployeeSmokerStatusRequired) {
    showSmokerContent = employeeSmoker;
  }
  if (benefit?.isDependentSmokerStatusRequired) {
    showSmokerContent = dependentSmoker;
  }

  let smokerStatusText: any = "";
  smokerStatusText = showSmokerContent?.lblNonSmokerStatusText;
  if (["0", 0].indexOf(benefit?.smokerStatus) > -1) smokerStatusText = showSmokerContent?.lblNonSmokerStatusText;
  if (["1", 1].indexOf(benefit?.smokerStatus) > -1) smokerStatusText = showSmokerContent?.lblSmokerStatusText;

  const headingTitleTxt = showSmokerContent ? (benefit?.smokerStatus !== 2 ? showSmokerContent?.lblChangeText : showSmokerContent?.lblButtonText) : "";

  const optionList = options && options?.length > 10 ? (isExpanded ? options : collapsedOptionList) : options;

  const showWaiverNotes = selectedOption?.showWaiverNotes ? selectedOption?.showWaiverNotes : false;

  //const lnkMore = getDisplayText(footerContent, "lnkMore");
  //const lnkLess = getDisplayText(footerContent, "lnkLess");
  // const closeButtonText = getDisplayText(footerContent, "btnClose");
  // const optionList = options && options.length > 10 ? (this.state.isExpanded ? options : this.state.collapsedOptionList) : options;
  // {
  //   /* Handle Employee Smoker Status */
  // }

  {
    /* Handle Dependent Smoker Status */
  }
  // const dependentSmoker = sessionStorage.getItem("dependentSmoker") ? JSON.parse(sessionStorage.getItem("dependentSmoker")) : null;
  // const dependentBtnTxt = (benefit.isDependentSmokerStatusRequired && benefit.smokerStatus === 2) ? dependentSmoker.lblButtonText : dependentSmoker.lblChangeText;

  /* if(this.state.expandSmokerBlock && benefit.isDependentSmokerStatusRequired) {
      dependentBtnTxt = dependentSmoker.lblHideText
    } */
  // const dependentErrorMsg = benefit.isDependentSmokerStatusRequired && benefit.smokerStatus === 2 && dependentSmoker ? dependentSmoker.lblErrorMessage : null;

  // let smokerStatus = benefit.isEmployeeSmokerStatusRequired || benefit.isDependentSmokerStatusRequired ? benefit.smokerStatus : -1;
  // let showSmokerContent = "";
  // if (benefit.isEmployeeSmokerStatusRequired) {
  //   showSmokerContent = employeeSmoker;
  // }
  // if (benefit.isDependentSmokerStatusRequired) {
  //   showSmokerContent = dependentSmoker;
  // }
  // const benefitID = this.props.benefitURL ? parseInt(this.props.benefitURL.split("/").pop()) : -1;

  // let smokerStatusText = "";
  // if (["0", 0].indexOf(benefit.smokerStatus) > -1) smokerStatusText = showSmokerContent?.lblNonSmokerStatusText;
  // if (["1", 1].indexOf(benefit.smokerStatus) > -1) smokerStatusText = showSmokerContent?.lblSmokerStatusText;

  // const employeeSmoker = sessionStorage.getItem("employeeSmoker") ? sessionStorage.getItem("employeeSmoker") : null;
  // const employeeErrorMsg = benefit.isEmployeeSmokerStatusRequired && benefit.smokerStatus === 2 && employeeSmoker ? employeeSmoker?.lblErrorMessage : null;

  // const showWaiverNotes = selectedOption?.showWaiverNotes ? selectedOption?.showWaiverNotes : false;
  // const showRemoveWaiver = selectedOption?.showRemoveWaiver ? selectedOption?.showRemoveWaiver : false;
  // const showAlternateCoverageDetails=benefit.showAlternateCoverageDetails ? benefit.showAlternateCoverageDetails : false;

  const showModalPopup = (modalName: any) => {
    setShowModal({ ...showModal, [modalName]: true });
  };

  const handleOpenBeneficiary = (benefitName: string) => {
    const benefitID = benefitURL ? parseInt(benefitURL.split("/").pop()) : -1;
    setFlexBeneficiaryBenefitID(benefitID, benefitName, () => {
      navigate("/Beneficiaries");
    });
  };
//Handle Package Beneficiaries
  const handlePackageBeneficiaries = (benefitName:string) => {
      setMultiBeneficiariesList(benefit,benefitName, () => {
      navigate("/MultiPackageBeneficiaries")
    })
  }

  const buttonInput = [
    // /*show Waiver Notes */
    showWaiverNotes && {
      variant: "outline-secondary btn-waivecoverage",
      display: lblWaiveCoverage?.display,
      onSubmit: () => showModalPopup("showMedicalWaiverDependent")
      // padlevel: "pb-1"
    },
    // /*show Remove Waive Coverage */

    benefit?.showRemoveWaiver &&
      lblRemoveWaiver?.display && {
        variant: "outline-secondary btn-removewaiver",
        display: lblRemoveWaiver?.display,
        onSubmit: handleRemoveWaiver //handleRemoveWaiver
      },
    /*show Waive Coverage */
    benefit?.showWaiveCoverage && {
      variant: "outline-secondary btn-waivecoverage",
      display: lblWaiveCoverage?.display,
      onSubmit: () => showModalPopup("showMedicalWaiver")
    },
    // /*show Dependents */
    benefit?.showDependents && {
      variant: "outline-secondary btn-changedependentcoverage",
      display: lblChangeDependents?.display,
      onSubmit: () => showModalPopup("showChangeDependentCoverage")
    },
    // /*show Alternate Coverage Details */
    (benefitName === "package" ? benefit?.showAlternateCoverageFlag : benefit?.showAlternateCoverageDetails) && {
      variant: "outline-secondary btn-changedependentcoverage",
      display: lblAlternateCoverageDetails?.display,
      onSubmit: () => showModalPopup("showAlternateCoverageDetails")
    },
    // /* [FLEXS-803] Beneficiaries button right align & Remove to be left */
    benefit?.removeBtnEOI &&
      benefit?.showBtnEOI && {
        variant: "outline-secondary btn-remove",
        display: lblRemoveEOI?.display,
        onSubmit: handleRemoveEOI
      },
    employeeSmoker &&
      benefit?.isEmployeeSmokerStatusRequired &&
      benefit?.smokerStatus === 2 && {
        variant: "outline-secondary btn-save",
        display: employeeSmoker?.lblButtonText,
        onSubmit: () => showModalPopup("expandSmokerBlock")
      },
    dependentSmoker &&
      benefit?.isDependentSmokerStatusRequired &&
      benefit?.smokerStatus === 2 && {
        variant: "outline-secondary btn-save",
        display: dependentSmoker?.lblButtonText,
        onSubmit: () => showModalPopup("expandSmokerBlock")
      },
    benefit?.showBeneficiariesAssignment && {
      variant: "outline-secondary btn-beneficiaries float-right",
      display: lblViewBeneficiaries?.display,
      onSubmit: ()=>benefitName === "package"?handlePackageBeneficiaries(benefit?.benefitName):handleOpenBeneficiary(benefit?.benefitName)
    }
  ];

  const expandCollapseBtnInput = [
    {
      variant: "link",
      display: isExpanded ? lnkLess.display : lnkMore.display,
      icon: isExpanded ? faChevronUp : faChevronDown,
      rightpos: true,
      onSubmit: () => toggleExpandCollapse()
    }
  ];

  const headers = benefit?.header;
  const headerData: any = [
    headers?.category && { headername: headers?.category },
    headers?.taxable && { headername: headers?.taxable },
    headers?.eoi && { headername: headers?.eoi },
    headers?.cost && { headername: headers?.cost },
    headers?.flex && { headername: headers?.flex },
    headers?.employerCost && { headername: headers?.employerCost },
    headers?.creditsApplied && { headername: headers?.creditsApplied },
    headers?.deduction && { headername: headers?.deduction }
  ];
  const optionsDiv: any = [];
  if (optionList?.length > 0)
    optionList?.map((opt: any, i: number) => {
      const optionName = `${opt?.optionName} ${opt?.coverage ? opt?.coverage : ""}`;
      const rowOptions: any = [];
      const rowOptionData: any = [
        headers?.category && {
          type: "text",
          display: opt?.category || "",
          datatitle: headers?.category
        },
        headers?.taxable && {
          type: "text",
          display: opt?.taxable || "",
          datatitle: headers?.taxable
        },
        headers?.eoi && {
          type: "text",
          display: opt?.eoi || "",
          datatitle: headers?.eoi
        },
        headers?.cost && {
          type: "text",
          display: opt?.cost || "",
          datatitle: headers?.cost
        },
        headers?.flex && {
          //   && //  opt?.showFlex
          type: "text",
          display: (opt?.showFlex && opt?.flex) || "",
          datatitle: headers?.flex
        },
        // : { type: "text", display:"", datatitle: headers?.flex },
        headers?.employerCost && {
          type: "text",
          display: opt?.employerCost || "",
          datatitle: headers?.employerCost
        },
        headers?.creditsApplied && {
          type: "text",
          display: opt?.creditsApplied || "",
          datatitle: headers?.creditsApplied
        },
        headers?.deduction && {
          type: "text",
          display: (opt?.showDeductions && opt?.deduction) || "",
          datatitle: headers?.deduction
        }
      ];

      rowOptions.push(rowOptionData);
      const tblOptions = {
        className: "w-100",
        header: {
          headerData: headerData
        },
        body: {
          gridRowData: rowOptions
        }
      };

      //Benefit option
      const tid = "-benefitID-" + benefitID + "-" + opt?.optionID;
      const options = {
        name: benefit?.benefitName?.replace(/\s+/g, "-").toLowerCase() + "-" + opt?.optionID,
        selected: opt?.isIneligible ? false : opt?.isSelected,
        className: `${opt?.isIneligible && "option-ineligble"} ${opt?.requireEOI && "option-eoi"}`,
        ischecked: true,
        fgclass: "",
        isCallbackParam: true,
        disabled: !opt.selectEnabled,
        isInvalid: opt?.isIneligible,
        handlechange: () => setValue(opt),
        optionsinput: [
          {
            id: opt?.selectEnabled ? "selectEnableCheckbox" + tid : "selectNoEnableCheckbox" + tid,
            text: optionName,
            value: opt.optionID
          }
        ]
      };
      //Benefit option End
      optionsDiv.push(
        <div
          key={benefitURL ? benefitURL + opt.optionName + i + "-option-div" : opt.optionName + i + "-option-div"}
          className={opt?.isSelected ? "selected mb-3 ml-selectedsecondary benefit-option" : "mb-3 benefit-option"}>
          <div id={benefitURL ? benefitURL + opt.optionName + i + "-option-div" : opt.optionName + i + "-option-div"} className="header d-block">
            <div className="row">
              <div className="col-9">
                <ChoiceListComponent choicelistdata={options} />
              </div>
              <div className="col-3">
                {opt.showToolTip && opt.toolTipDescription && (
                  <TooltipIconComponent
                    inputData={{
                      ariaLabel: toolTipIcon.textToRead,
                      className: "tooltip-icon icon-action float-right",
                      onSubmit: () => toggleDescription(opt, "showTooltip-benefitID-" + benefitID + "-" + i)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-12 col-lg-12 benefit-option-body ml-benefitoptions">
            <TableComponent uniqueKey={benefitURL ? benefitURL + opt.optionName + i + "-option-table" : opt.optionName + i + "-option-table"} benefitName={benefitName} tableObject={tblOptions}></TableComponent>
          </div>
          {benefit.showBtnEOI && opt.requireEOI ? (
            <div className="additionaltext clearfix">
              <p dangerouslySetInnerHTML={{ __html: lblEOITitle.display }} />
              <p className={`m-0 `} dangerouslySetInnerHTML={{ __html: lblEOINote.display }} />
            </div>
          ) : (
            opt.description &&
            opt.isSelected && (
              <div className="additionaltext clearfix">
                <p className={`m-0 `} dangerouslySetInnerHTML={{ __html: opt.description }} />
              </div>
            )
          )}
        </div>
      );
    });

  const handleShowHelp = () => {
    const helpLink = `Help/${benefit?.helpLink}`;
    if (helpLink) {
      dispatch(
        getCustomPopUpURL(helpLink, () => {
          showModalPopup("showHelpContent");
        })
      );
    }
  };

  const helpButtonInput = [
    {
      icon: faQuestionCircle,
      iconclassname: "tooltip-icon icon-action btn-nocolor",
      ariaLabel: lblSeeDetails.textToRead,
      onSubmit: handleShowHelp,
      isSpanOnly: true,
      className: "icon-action"
    }
  ];

  const handleClose = () => {
    setbenefitToolTipDesc({ showDesc: false, description: "", toolTipId: "", toolTipHeader: "" });
    setShowModal({
      showHelpContent: false,
      showDesc: false,
      showChangeDependentCoverage: false,
      showMedicalWaiver: false,
      showMedicalWaiverDependent: false,
      showAlternateCoverageDetails: false,
      expandSmokerBlock: false
    });
  };

  const modalObject = {
    showModal:
      showbenefitToolTipDesc?.showDesc ||
      showModal?.showMedicalWaiverDependent ||
      showModal?.showHelpContent ||
      showModal?.showMedicalWaiver ||
      showModal?.showAlternateCoverageDetails ||
      showModal?.showChangeDependentCoverage ||
      showModal?.expandSmokerBlock,
    clickHandler: handleClose,
    dialogClass: showModal?.showChangeDependentCoverage || showbenefitToolTipDesc?.showDesc || showModal?.expandSmokerBlock ? "" : "ml-addnewdependent coveragewaiverpopup",
    head: {
      modalTitle:
        (showbenefitToolTipDesc?.showDesc && showbenefitToolTipDesc?.toolTipHeader) ||
        (showModal?.showHelpContent && benefit?.benefitName) ||
        ((showModal?.showMedicalWaiver || showModal?.showMedicalWaiverDependent) && lblWaiveOptionDialogTitle.display) ||
        (showModal?.showAlternateCoverageDetails && (lblAlternateCoverageDetailsTitle.display || lblDependentCoverageWaiverTitle.display)) ||
        (showModal?.showChangeDependentCoverage && lblDependentsCoveredUnderThisPlan.display) ||
        (showModal?.expandSmokerBlock && headingTitleTxt)
    },
    body: {
      messageText: (showbenefitToolTipDesc?.showDesc && showbenefitToolTipDesc?.description) || (showModal?.showHelpContent && helpContentResponse?.result?.content)
    }
  };

  // Modal Object Data End
  const smokerContentObject: any = smokerStatusText &&
    benefit?.smokerStatus !== 2 && {
      head: {
        gridtitle: showSmokerContent?.titleText,
        texttoread: showSmokerContent?.titleText,
        showediticon: true,
        editbtndata: {
          className: "link icon-action edit-icon float-right",
          onSubmit: () => showModalPopup("expandSmokerBlock")
        }
      },
      body: {
        messageText: smokerStatusText
      }
    };

  return benefit ? (
    <>
      <div className={`healthcarecontent clearfix card`}>
        <div className="card-header">
          <div className="ml-titledescription d-flex justify-content-between">
            <div className="titlehelpicon">
              <DescriptionComponent level={2} hideextra={true} heading={benefit?.benefitName} />
              {benefit?.showHelp && <ButtonComponent btndata={helpButtonInput} />}
            </div>
            {/* benefit effective date */}
            {benefit?.lblBenefitNotes && <span className={`float-right m-0`} role="alert" dangerouslySetInnerHTML={{ __html: benefit?.lblBenefitNotes }} />}
            {benefit?.benefitNote && (
              <span className="float-right" role="alert">
                {benefit?.benefitNote}
              </span>
            )}
          </div>
        </div>
        <div className="card-body">
          {requiresOtherCoverage && <AlertComponent propmessageType={`info warning note`} propmessage={lblOtherCoverageRequiredNote.display} />}
          {provincesRequiringOtherCoverage && <AlertComponent propmessageType={`info warning note`} propmessage={lblOtherCoverageRequiredForProvince.display} />}
          {benefitName === "package" && benefit.waivedPremuimRequiredNote && <AlertComponent propmessageType="primary note" propmessage={benefit.waivedPremuimRequiredNote} />}
          {benefit.showLinkedNote && <AlertComponent notBootStrapClass={true} propmessageType="warning note" propmessage={benefit.linkedNote} />}
          {benefit.hasUnitCoverage && <UnitCoverageComponent benefit={benefit} benefitURL={benefitURL} unitCoverageMessage={unitCoverageMessage} />}
          <div className={`ml-options mt-md-4 ${benefit.hasUnitCoverage ? "" : "float-none"} clearfix`}>
            {/* {benefit.isWaived || benefit.isTerminated ? <p className={`m-0`}>{benefit.terminatedORWaived}</p> : benefitName !== "package" && optionsDiv}
            {benefitName === "package" && optionsDiv} */}
            {/* {benefit.waivedPremuimRequiredNote && <label className={`warning note`} role='alert' dangerouslySetInnerHTML={{ __html: benefit.waivedPremuimRequiredNote }} />} */}

            {benefitName === "package" ? (
              <>
                {(benefit.isWaived || benefit.isTerminated) && benefit.terminatedORWaived}
                {optionsDiv}
              </>
            ) : (
              <>{benefit.isWaived || benefit.isTerminated ? benefit.terminatedORWaived : optionsDiv}</>
            )}
          </div>
          {options?.length > 10 && (
            <div className="ml-showmore d-flex justify-content-end">
              <ButtonComponent btndata={expandCollapseBtnInput} />
            </div>
          )}
          {benefit?.errorMessage && <AlertComponent propmessageType={`danger error-alert`} propmessage={benefit?.errorMessage} />}
          {employeeErrorMsg && <AlertComponent propmessageType={`danger error-alert`} propmessage={employeeErrorMsg} />}
          {dependentErrorMsg && <AlertComponent propmessageType={`danger error-alert`} propmessage={dependentErrorMsg} />}
          <div className={`ml-popupbuttons d-flex justify-content-between`}>
            <ButtonComponent btndata={buttonInput} />
          </div>
          {smokerContentObject && <CardComponent gridObject={smokerContentObject} />}
        </div>
      </div>
      {
        <ModalComponent modalObject={modalObject}>
          {showModal?.showChangeDependentCoverage && (
            <ChangeDependentCoverageComponent
              benefitURL={benefitURL}
              isMultiSelectDependents={benefit?.isMultiSelectDependents}
              dependentsThisPlan={benefit?.dependentsThisPlan}
              dependentsOtherPlan={benefit?.dependentsOtherPlan}
              contents={benefit?.contentResource?.contents}
              handleClose={handleClose}
            />
          )}
          {(showModal?.showMedicalWaiverDependent || showModal?.showMedicalWaiver || showModal?.showAlternateCoverageDetails) && (
            <MedicalWaiverComponent
              benefitURL={benefitURL}
              benefit={benefit}
              type={showModal?.showMedicalWaiver && benefitName !== "package" ? true : false}
              handleCancel={handleClose}
              isPackage={benefitName === "package" ? true : false}
              isOptOut={benefitName === "package" && showModal?.showMedicalWaiverDependent}
              selectedOption={selectedOption}
              showAlternateCoverageDetails={showModal?.showAlternateCoverageDetails ? true : false}
            />
          )}
          {showModal?.expandSmokerBlock && showSmokerContent && benefit?.smokerStatus !== -1 && (
            <DeclareSmokerStatusComponent
              isEmployeeSmokerStatusRequired={benefit?.isEmployeeSmokerStatusRequired}
              smokerStatus={benefit?.smokerStatus}
              showSmokerContent={showSmokerContent}
              handleCancel={handleClose}
              selectedOptionProp={selectedOption}
              benefitURL={benefitURL}
            />
          )}
        </ModalComponent>
      }
    </>
  ) : null;
};
export default ShellComponent;
